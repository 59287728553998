import {
  FeaturedItemGrid,
  FlexRow,
  H2,
  Link,
  ShowOnDesktop,
  ShowOnMobile,
  Stack,
} from "atoms";
import { ProductCard } from "./ProductCard";
import type { CollectionQueryProductData } from "../api/useCollectionQuery";

type ShopPageProductsProps = {
  products: CollectionQueryProductData[];
  title?: string;
  linkUrl?: string;
  linkLabel?: string;
};

function ShopProductRow({
  products,
  title,
  linkUrl,
  linkLabel,
}: ShopPageProductsProps) {
  if (!products.length) {
    return null;
  }
  return (
    <Stack gap="sm">
      {title && linkUrl && (
        <FlexRow justifyContent="space-between">
          {title && (
            <H2 size="lg" style={{ margin: 0 }}>
              {title}
            </H2>
          )}

          <ShowOnDesktop>
            <Link variant="primary" size="xs" to={linkUrl}>
              {linkLabel ?? "View All"}
            </Link>
          </ShowOnDesktop>
        </FlexRow>
      )}
      <FeaturedItemGrid>
        {products.map((product) => (
          <ProductCard product={product} key={product.id} />
        ))}
      </FeaturedItemGrid>

      <ShowOnMobile>
        <Link variant="primary" size="xs" to={linkUrl}>
          {linkLabel ?? "View All"}
        </Link>
      </ShowOnMobile>
    </Stack>
  );
}

export { ShopProductRow };
