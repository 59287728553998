import styled from "@emotion/styled";
import { List } from "./List";

const minSize = "calc(150px + 7vw)";

const Grid = styled.div(({ theme }) => ({
  display: "grid",
  gap: theme.spacing.gap,
  gridTemplateColumns: "100%",
  "@media (min-width: 440px)": {
    gridTemplateColumns: `repeat(auto-fill, minmax(min(${minSize}, 50% - 1rem), 1fr))`,
  },
  "@media (min-width: 1040px)": {
    gridTemplateColumns: `repeat(4, 1fr)`,
  },
  // "@media (min-width: 1440px)": {
  //   gridTemplateColumns: `repeat(auto-fill, minmax(min(280px, 50% - 1rem), 1fr))`,
  // },
}));

interface GridPlaceholderProps {
  cols: number;
  length: number;
}

/**
 * Fill out a <Grid> component with empty children to specified column size
 */
function GridPlaceholders({ cols, length }: GridPlaceholderProps) {
  const placeholders = cols - length;

  if (placeholders <= 0) {
    return null;
  }

  return (
    <>
      {Array.from({ length: placeholders }, (_, i) => (
        <div key={i} />
      ))}
    </>
  );
}

/**
 * Creates a responsive "single row" grid for 4 items
 * xs: 2x2; sm: 3x1; lg: 4x1
 */
const FeaturedItemGrid = styled(List)<{ showAll?: boolean }>(
  ({ showAll, theme }) => ({
    display: "grid",
    gridTemplateColumns: `repeat(2, 1fr)`,
    gap: "1rem",
    padding: 0,
    ...(!showAll && {
      "&>*:nth-of-type(1n + 5)": {
        display: "none",
      },
    }),
    [theme.breakpoints.tablet]: {
      gridTemplateColumns: `repeat(3, 1fr)`,
      ...(!showAll && {
        "&>*:nth-of-type(4)": {
          display: "none",
        },
      }),
    },
    [theme.breakpoints.desktopMedium]: {
      gridTemplateColumns: `repeat(4, 1fr)`,
      ...(!showAll && {
        "&>*:nth-of-type(4)": {
          display: "flex",
        },
      }),
    },
  }),
);
FeaturedItemGrid.defaultProps = {
  type: "blank",
};

export { Grid, FeaturedItemGrid, GridPlaceholders };
