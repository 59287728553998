import styled from "@emotion/styled";
import { useToggle } from "utils/hooks";
import { FlexRow } from "./FlexRow";
import { Stack } from "./Stack";
import { Button } from "./Button";
import { H3 } from "./Typography";

type LoadingMessageProps = {
  children: string | React.ReactNode;
  showBackdrop?: boolean;
};

function LoadingMessage({
  showBackdrop = false,
  children,
  ...props
}: LoadingMessageProps) {
  const [isAnimating, toggleIsAnimating] = useToggle(true);
  return (
    <LoadingScrim showBackdrop={showBackdrop} {...props} aria-live="polite">
      <LoadingWrapper showBackdrop={showBackdrop}>
        <Stack gap="md">
          {typeof children === "string" ? (
            <H3
              textTransform="none"
              textAlign="center"
              className="LoadingMessageText"
            >
              {children}
            </H3>
          ) : (
            <Stack gap="sm" className="LoadingMessageText">
              {children}
            </Stack>
          )}

          <FlexRow
            justifyContent="center"
            gap=".75em"
            className="LoadingMessageDots"
          >
            <Dot isAnimating={isAnimating} />
            <Dot isAnimating={isAnimating} />
            <Dot isAnimating={isAnimating} />
            <Dot isAnimating={isAnimating} />
          </FlexRow>

          <FlexRow justifyContent="center" className="LoadingMessagePause">
            <PauseButton
              variant="text"
              size="xxs"
              onClick={() => toggleIsAnimating()}
            >
              <span>
                {isAnimating ? <span>Pause </span> : <span>Play </span>}
                <span>animation</span>
              </span>
            </PauseButton>
          </FlexRow>
        </Stack>
      </LoadingWrapper>
    </LoadingScrim>
  );
}

const LoadingScrim = styled.div<{ showBackdrop: boolean }>(
  ({ showBackdrop }) => ({
    ...(showBackdrop && {
      backdropFilter: "blur(1px)",
      backgroundColor: "rgb(255 255 255 / .25)",
      bottom: 0,
      left: 0,
      position: "fixed",
      right: 0,
      top: 0,
      zIndex: 10000,
    }),
  }),
);

const LoadingWrapper = styled.div<{ showBackdrop: boolean }>(
  ({ showBackdrop, theme }) => ({
    alignItems: "center",
    display: "flex",
    backgroundColor: theme.colors.bg,
    borderRadius: theme.borderRadius.sm,
    boxShadow: theme.boxShadow.light,
    justifyContent: "center",
    paddingBlock: "3em",
    paddingInline: "2em",
    ...(showBackdrop && {
      boxShadow: theme.boxShadow.dark,
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    }),
  }),
);

const Dot = styled.span<{ isAnimating: boolean }>(({ isAnimating, theme }) => ({
  borderRadius: theme.borderRadius.round,
  height: "1.2em",
  width: "1.2em",
  "@keyframes dotJump": {
    "0%, 40%, 100%": {
      transform: "translateY(0)",
    },
    "15%": {
      transform: "translateY(-1.1em)",
    },
  },
  "@media (prefers-reduced-motion: no-preference)": {
    animation: "dotJump 1.2s ease-out 0s forwards infinite",
    animationPlayState: isAnimating ? "running" : "paused",
  },

  "&:nth-of-type(1)": {
    animationDelay: "0s",
    backgroundColor: theme.colors.accent1,
  },
  "&:nth-of-type(2)": {
    animationDelay: ".075s",
    backgroundColor: theme.colors.accent2,
  },
  "&:nth-of-type(3)": {
    animationDelay: ".15s",
    backgroundColor: theme.colors.accent4,
  },
  "&:nth-of-type(4)": {
    animationDelay: ".225s",
    backgroundColor: theme.colors.accent3,
  },
}));

const PauseButton = styled(Button)({
  textDecoration: "none",
  fontWeight: "bold",
  opacity: 0.6,
  padding: 5,
  marginBottom: "-2em",
});

export { LoadingMessage };
