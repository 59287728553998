import * as React from "react";
import {
  useController,
  useFieldArray,
  UseFieldArrayProps,
  UseFormReturn,
} from "react-hook-form";
import {
  FieldError,
  Input,
  Label,
  Legend,
  RepeaterFields,
  RepeaterField,
  Checkbox,
} from "atoms";
import { SelectReviewableLinkType } from "features/misc";
import type { ProjectFormFieldsPreTransform } from "../types";

type ProjectFormControl =
  UseFieldArrayProps<ProjectFormFieldsPreTransform>["control"];

type ProjectTeamInputProps = {
  register: UseFormReturn<ProjectFormFieldsPreTransform>["register"];
  errors: UseFormReturn<ProjectFormFieldsPreTransform>["formState"]["errors"];
  control: ProjectFormControl;
};

function ProjectTeamInput({
  control,
  errors,
  register,
  ...props
}: ProjectTeamInputProps) {
  const { fields, append, move, remove } = useFieldArray({
    name: "team",
    control,
  });

  const handleAppend = () =>
    append({
      teamMemberName: "",
      teamMemberRoles: [],
      teamMemberLinks: [],
    });

  return (
    <Label as="fieldset" {...props}>
      <Legend>Team Members</Legend>

      <RepeaterFields handleAppend={handleAppend} rowLabel="Team Member">
        {fields.map((item, index) => (
          <RepeaterField
            key={item.id}
            order={index}
            rowLabel="Team Member"
            {...RepeaterField.createHandlers(
              index,
              fields.length,
              move,
              remove,
            )}
          >
            <Label>
              Name
              <Input {...register(`team.${index}.teamMemberName` as const)} />
            </Label>

            <Legend>
              Roles
              <RoleCheckboxes control={control} index={index} />
            </Legend>

            <Legend>
              Links
              <TeamLinkRepeater
                teamMemberIndex={index}
                register={register}
                control={control}
                errors={errors}
              />
            </Legend>
          </RepeaterField>
        ))}
      </RepeaterFields>
    </Label>
  );
}

type RoleCheckboxProps = {
  control: ProjectFormControl;
  index: number;
};

function RoleCheckboxes({ control, index }: RoleCheckboxProps) {
  // NOTE: react-hook-form useNestedField array can't handle nested inputs with the same name, like
  // this field group of checkboxes. The workaround is to use a controlled component that maintains
  // its own state and updates the parent form manually via the useController hook below. On render,
  // register the new "input" with a single name as part of the Project form, pass its control down,
  // and use a default value of empty array if not provided.
  const { field } = useController({
    name: `team.${index}.teamMemberRoles`,
    control,
    defaultValue: [],
  });

  // Using the form's controlled context, initialize this "input's" initial controlled state
  const [roles, setRoles] = React.useState<string[]>(field.value);

  // Create event handler for our individual checkboxes that manages the "input's" state and updates
  // the parent form context.
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const role = event.target.value;
    const newRoles = roles.includes(role)
      ? roles.filter((r) => r !== role)
      : [...roles, role];
    field.onChange(newRoles);
    setRoles(newRoles);
  };

  return (
    <>
      <Checkbox
        {...field}
        label="Founder"
        value="founder"
        onChange={onChange}
      />
      <Checkbox {...field} label="Artist" value="artist" onChange={onChange} />
    </>
  );
}

type TeamLinkRepeaterProps = ProjectTeamInputProps & {
  teamMemberIndex: number;
};

function TeamLinkRepeater({
  teamMemberIndex,
  control,
  errors,
  register,
}: TeamLinkRepeaterProps) {
  const { fields, append, move, remove } = useFieldArray({
    control,
    name: `team.${teamMemberIndex}.teamMemberLinks`,
  });

  const handleAppend = () =>
    append({
      type: "discord",
      url: "",
    });

  return (
    <RepeaterFields handleAppend={handleAppend} rowLabel="Link">
      {fields.map((item, linkIndex) => (
        <RepeaterField
          key={item.id}
          order={linkIndex}
          rowLabel="Link"
          {...RepeaterField.createHandlers(
            linkIndex,
            fields.length,
            move,
            remove,
          )}
        >
          <Label>
            Type
            <SelectReviewableLinkType
              {...register(
                `team.${teamMemberIndex}.teamMemberLinks.${linkIndex}.type`,
              )}
            />
          </Label>

          <Label>
            URL
            <Input
              {...register(
                `team.${teamMemberIndex}.teamMemberLinks.${linkIndex}.url`,
                {
                  pattern: {
                    value: /^https:\/\//,
                    message: "URL needs to include https://",
                  },
                },
              )}
            />
            {errors?.team?.[teamMemberIndex]?.teamMemberLinks?.[linkIndex]
              ?.url && (
              <FieldError block>
                {
                  errors?.team?.[teamMemberIndex]?.teamMemberLinks?.[linkIndex]
                    ?.url?.message
                }
              </FieldError>
            )}
          </Label>
        </RepeaterField>
      ))}
    </RepeaterFields>
  );
}

export { ProjectTeamInput };
