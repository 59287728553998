import { flattenConnection } from "@shopify/hydrogen-react";
import type {
  MediaConnection,
  Metaobject,
  MetaobjectConnection,
  Product,
  ProductConnection,
  ProductVariant,
  ProductVariantConnection,
  SelectedOption,
} from "@shopify/hydrogen-react/storefront-api-types";
import { useQuery, useQueryClient } from "react-query";
import { sanitizeShopifyHTML } from "../components/ShopifyRichText";
import type { ProductStatus } from "../types";
import { parseGid } from "../utils/shopifyId";
import { client } from "./client";
import { productKeys } from "./queryKeys";

const PRODUCT_QUERY = `#graphql
  query product($handle: String!, $selectedOptions: [SelectedOptionInput!]!) {
    product(handle: $handle) {
      id
      availableForSale
      description
      descriptionHtml
      productType
      title
      vendor
      featuredImage {
        id
        altText
        height
        width
        url
      }
      artistId: metafield(namespace: "hug", key: "artist_id") {
        value
      }
      maximumQuantity: metafield(namespace: "hug", key: "maximum_quantity") {
        value
      }
      publishedAt: metafield(namespace: "hug", key: "published_at") {
        value
      }
      status: metafield(namespace: "hug", key: "status") {
        value
      }
      templateId: metafield(namespace: "hug", key: "template_id") {
        value
      }
      details: metafield(namespace: "hug", key: "product_details") {
        value
        references(first: 10) {
          ...on MetafieldReferenceConnection {
            edges {
              node {
                ...on Metaobject {
                  id
                  fields {
                    key
                    value
                  }
                }
              }
            }
          }
        }
      }
      upsells: metafield(namespace: "hug", key: "product_upsells") {
        value
        references(first: 10) {
          ...on MetafieldReferenceConnection {
            edges {
              node {
                ...on Product {
                  id
                  title
                  description
                  variants(first: 1) {
                    nodes {
                      id
                      availableForSale
                      quantityAvailable
                      sku
                      title
                      image {
                        id
                        altText
                        height
                        url
                        width
                      }
                      price {
                        amount
                        currencyCode
                      }
                      compareAtPrice {
                        amount
                        currencyCode
                      }
                      selectedOptions {
                        name
                        value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      options {
        name,
        values
      }
      priceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      selectedVariant: variantBySelectedOptions(selectedOptions: $selectedOptions) {
        id
        availableForSale
        quantityAvailable
        sku
        title
        selectedOptions {
          name
          value
        }
        image {
          id
          url
          altText
          width
          height
        }
        price {
          amount
          currencyCode
        }
        compareAtPrice {
          amount
          currencyCode
        }
      }
      variants(first: 2) {
        nodes {
          id
          availableForSale
          quantityAvailable
          sku
          title
          selectedOptions {
            name
            value
          }
          image {
            id
            url
            altText
            width
            height
          }
          price {
            currencyCode
            amount
          }
          compareAtPrice {
            currencyCode
            amount
          }
        }
      }
      media(first: 20) {
        nodes {
          id
          mediaContentType
          ...on MediaImage {
            image {
              url
              altText
              height
              width
            }
          }
        }
      }
    }
  }
`;

type VariantNode = Pick<
  ProductVariant,
  | "id"
  | "availableForSale"
  | "quantityAvailable"
  | "sku"
  | "title"
  | "image"
  | "price"
  | "compareAtPrice"
  | "selectedOptions"
>;

type UpsellNodeReference = Pick<Product, "id" | "title" | "description"> & {
  variants?: ProductVariantConnection;
};

type UpsellNode = Pick<Product, "id" | "title" | "description"> & {
  variants: VariantNode[];
};

type ProductResp = Pick<
  Product,
  | "id"
  | "availableForSale"
  | "description"
  | "descriptionHtml"
  | "featuredImage"
  | "options"
  | "priceRange"
  | "productType"
  | "title"
  | "vendor"
  | "compareAtPriceRange"
> & {
  artistId?: { value: string };
  status?: { value: ProductStatus };
  templateId?: { value: string };
  publishedAt?: { value: string };
  maximumQuantity?: { value: string };
  details?: {
    value: string;
    references: Partial<MetaobjectConnection>;
  };
  selectedVariant?: VariantNode | null;
  variants: {
    nodes: VariantNode[];
  };
  media: MediaConnection;
  upsells?: {
    value: string;
    references: Partial<ProductConnection>;
  };
};

const getProduct = (handle?: string, selectedOptions: SelectedOption[] = []) =>
  handle
    ? client.query<{ product?: ProductResp }>(PRODUCT_QUERY, {
        handle,
        selectedOptions,
      })
    : Promise.reject(new Error("Invalid product handle"));

const useProductQuery = (handle?: string, options: SelectedOption[] = []) => {
  const queryClient = useQueryClient();

  return useQuery(
    productKeys.detail(handle, options),
    () => getProduct(handle, options),
    {
      enabled: !!handle,
      placeholderData: () => {
        // Find placeholder data so we don't start with some product data when switching between
        // options and fetching new variants. This will find any existing queries for that product
        // regardless of options and "preload" that product data until the fetch completes and
        // overwrites this placeholder content with the newly fetched variant data.
        const priorQueries = queryClient.getQueriesData<{
          product?: ProductResp;
        }>(productKeys.detail(handle));
        return priorQueries.find((d) => !!d)?.[1];
      },
      select: ({ product }) => {
        if (!product) {
          return undefined;
        }

        // Flatten connections to edges/nodes
        const details = !product.details?.references
          ? []
          : flattenConnection(product.details.references).filter(
              (d): d is Metaobject => !!d,
            );
        const media = !product.media ? [] : flattenConnection(product.media);
        const upsells: UpsellNode[] = !product.upsells?.references
          ? []
          : flattenConnection(product.upsells.references)
              .filter((u): u is UpsellNodeReference => !!u)
              .map(({ variants, ...upsell }) => ({
                ...upsell,
                variants: !variants ? [] : flattenConnection(variants),
              }));
        const variants = !product.variants
          ? []
          : flattenConnection(product.variants);

        return {
          ...product,
          id: parseGid(product.id).id,
          handle,
          gid: product.id,
          artistId: product.artistId?.value,
          maximumQuantity: product.maximumQuantity?.value,
          publishedAt: product.publishedAt?.value,
          status: product.status?.value,
          templateId: product.templateId?.value,
          details,
          descriptionHtml: sanitizeShopifyHTML(product.descriptionHtml ?? ""),
          media,
          upsells,
          hasVariants: variants.length > 1,
          selectedVariant:
            product.selectedVariant ?? (variants[0] as VariantNode | undefined),
          variants,
        };
      },
    },
  );
};

type ProductQueryResponse = NonNullable<
  ReturnType<typeof useProductQuery>["data"]
>;

export type { UpsellNode, VariantNode, ProductQueryResponse };
export { useProductQuery };
