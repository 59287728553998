import * as React from "react";
import styled from "@emotion/styled";
import {
  Button,
  Checkbox,
  DatePicker,
  FieldError,
  FlexRow,
  Form,
  H1,
  Hr,
  Input,
  Label,
  Legend,
  Link,
  Select,
  TagCheckbox,
  TextArea,
  LoadingSpinner,
  Box,
  Stack,
  IconArrow,
} from "atoms";
import { ImageUpload } from "features/images";
import { AffiliatedReviewableInput } from "features/search";
import { fieldOptions, validators } from "utils/form";
import { scrollToTop } from "utils/scroll";
import { LinksInput } from "features/misc";
import { useProjectForm } from "../api/useProjectForm";
import {
  blockchains,
  doxxed as doxxedStatuses,
  founderAttributes,
  mintStatuses,
  missionOrCauses,
  roadmapCategories,
} from "../data";
import type { Project, ProjectFormFieldsPreTransform } from "../types";
import { ProjectTeamInput } from "./ProjectTeamInput";
import { FormFooter } from "./ArtistForm";

const numberFieldOptions = {
  valueAsNumber: true,
  ...fieldOptions.min(0),
};

const textFieldMaxLength = 100;
const textFieldRequirements = {
  ...fieldOptions.required,
  ...fieldOptions.maxLength(textFieldMaxLength),
};

const textAreaMaxLength = 2000;
const textAreaRequirements = {
  ...fieldOptions.required,
  ...fieldOptions.maxLength(textAreaMaxLength),
};

type Props = {
  project?: Project;
};

function ProjectForm({ project }: Props) {
  const {
    control,
    formState: { errors, isDirty },
    isEdit,
    mutation: { data, isSuccess, error, isError, isLoading: isProjectUpdating },
    onSubmit,
    setFocus,
    register,
    setValue,
  } = useProjectForm(project);

  const isDuplicateId =
    error instanceof Error && error.message.includes("ID Already Used");

  React.useEffect(() => {
    if (isDuplicateId) {
      setFocus("id");
    }
  }, [isDuplicateId, setFocus]);

  if (isSuccess) {
    scrollToTop();

    return (
      <Box fullWidth>
        <H1>Thanks!</H1>
        <ReturnLink variant="primary" arrow="dr" to={`/projects/${data?.id}`}>
          View Project
        </ReturnLink>
      </Box>
    );
  }

  return (
    <SForm onSubmit={onSubmit}>
      <Box fullWidth>
        <Stack gap="lg">
          {!isEdit && (
            <Label>
              Project ID / SLUG
              <Input
                {...register("id", {
                  ...textFieldRequirements,
                  validate: validators.validSlug,
                })}
                maxLength={textFieldMaxLength}
                isInvalid={!!errors.id}
                aria-invalid={!!errors.id}
              />
              {errors.id && <FieldError>{errors.id.message}</FieldError>}
              {isDuplicateId && (
                <FieldError>
                  Sorry, that Project ID already exists. Choose another and
                  resubmit.
                </FieldError>
              )}
            </Label>
          )}

          <Label>
            Project Name
            <Input
              {...register("name", textFieldRequirements)}
              maxLength={textFieldMaxLength}
              isInvalid={!!errors.name}
              aria-invalid={!!errors.name}
            />
            {errors.name && <FieldError>{errors.name.message}</FieldError>}
          </Label>

          <Label>
            Description
            <TextArea
              {...register("description", textAreaRequirements)}
              maxLength={textAreaMaxLength}
              isInvalid={!!errors.description}
              aria-invalid={!!errors.description}
            />
            {errors.description && (
              <FieldError>{errors.description.message}</FieldError>
            )}
          </Label>

          <Label>
            Highlights
            <Input
              {...register("highlights0")}
              isInvalid={!!errors.highlights0}
              aria-invalid={!!errors.highlights0}
            />
            {errors.highlights0 && (
              <FieldError>{errors.highlights0.message}</FieldError>
            )}
            <Input
              {...register("highlights1")}
              isInvalid={!!errors.highlights1}
              aria-invalid={!!errors.highlights1}
            />
            {errors.highlights1 && (
              <FieldError>{errors.highlights1.message}</FieldError>
            )}
            <Input
              {...register("highlights2")}
              isInvalid={!!errors.highlights2}
              aria-invalid={!!errors.highlights2}
            />
            {errors.highlights2 && (
              <FieldError>{errors.highlights2.message}</FieldError>
            )}
          </Label>

          <Hr />

          <fieldset>
            <Legend>Hero Image</Legend>

            <ImageUpload
              control={control}
              fieldName="heroImage"
              required
              errorMessage={errors.heroImage?.url?.message}
              src={project?.heroImage}
              aspect="square"
              entityType="PROJECT"
            />
          </fieldset>

          <fieldset>
            <Legend>Banner Image</Legend>

            <ImageUpload
              control={control}
              fieldName="bannerImage"
              src={project?.bannerImage}
              aspect="banner"
              entityType="PROJECT"
              errorMessage={errors.bannerImage?.url?.message}
              previewWidth="100%"
            />
          </fieldset>

          <Hr />

          <Label as="fieldset">
            <Legend>Mint Status</Legend>

            {mintStatuses.map((status) => (
              <Checkbox
                key={status}
                {...register("mintStatus")}
                label={status}
                value={status}
              />
            ))}

            {errors.mintStatus && (
              <FieldError>{errors.mintStatus.message}</FieldError>
            )}
          </Label>

          <FlexRow itemsFlex={1} alignItems="flex-start">
            <Label>
              Blockchain
              <Select
                {...register("blockchain", { ...fieldOptions.required })}
                isInvalid={!!errors.blockchain}
                aria-invalid={!!errors.blockchain}
              >
                {blockchains.map((chain) => (
                  <option key={chain} value={chain}>
                    {chain[0].toUpperCase()}
                    {chain.slice(1)}
                  </option>
                ))}
              </Select>
              {errors.blockchain && (
                <FieldError>{errors.blockchain.message}</FieldError>
              )}
            </Label>

            <Label>
              First Mint Date
              <DatePicker
                {...register("mintDate")}
                isInvalid={!!errors.mintDate}
                aria-invalid={!!errors.mintDate}
                preventCalendar
              />
              {errors.mintDate && (
                <FieldError>{errors.mintDate.message}</FieldError>
              )}
              <Button
                variant="blank"
                size="xs"
                onClick={() => setValue("mintDate", "")}
              >
                Clear Date
              </Button>
            </Label>
          </FlexRow>

          <FlexRow itemsFlex={1}>
            <Label>
              Collection Size
              <Input
                {...register("collectionSize", numberFieldOptions)}
                type="number"
                isInvalid={!!errors.collectionSize}
                aria-invalid={!!errors.collectionSize}
              />
              {errors.collectionSize && (
                <FieldError>{errors.collectionSize.message}</FieldError>
              )}
            </Label>

            <Label>
              Doxxed
              <Select
                {...register("doxxed")}
                isInvalid={!!errors.doxxed}
                aria-invalid={!!errors.doxxed}
              >
                <option value="">-</option>
                {doxxedStatuses.map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </Select>
              {errors.doxxed && (
                <FieldError>{errors.doxxed.message}</FieldError>
              )}
            </Label>
          </FlexRow>

          <Hr />

          <Label as="fieldset">
            <Legend>Choose tags to describe the Founders of the Project</Legend>
            {founderAttributes.map((value) => (
              <TagCheckbox
                {...register("founderAttributes")}
                key={value}
                variant="founderAttribute"
                label={value}
                value={value}
              />
            ))}
            {errors.founderAttributes && (
              <FieldError block>{errors.founderAttributes.message}</FieldError>
            )}
          </Label>

          <Label as="fieldset">
            <Legend>
              Choose tags to describe the Mission or Cause of the Project
            </Legend>
            {missionOrCauses.map((value) => (
              <TagCheckbox
                {...register("missionOrCause")}
                key={value}
                variant="missionOrCause"
                label={value}
                value={value}
              />
            ))}
            {errors.missionOrCause && (
              <FieldError block>{errors.missionOrCause.message}</FieldError>
            )}
          </Label>

          <Label as="fieldset">
            <Legend>Choose roadmap categories for the Project</Legend>
            {roadmapCategories.map((value) => (
              <TagCheckbox
                {...register("roadmapCategories")}
                key={value}
                variant="roadmap"
                label={value}
                value={value}
              />
            ))}
            {errors.roadmapCategories && (
              <FieldError block>{errors.roadmapCategories.message}</FieldError>
            )}
          </Label>

          <Hr />

          <LinksInput<ProjectFormFieldsPreTransform>
            control={control}
            register={register}
            errors={errors}
            name="links"
          />

          <Hr />

          <ProjectTeamInput
            control={control}
            register={register}
            errors={errors}
          />

          <Hr />

          <fieldset>
            <Legend>Choose Affiliated Projects</Legend>

            <AffiliatedReviewableInput
              control={control}
              name="affiliations"
              editingId={project?.id}
              typeLabel="Projects"
            />
          </fieldset>

          <Hr />

          <Label>
            Status
            <Select {...register("status", { ...fieldOptions.required })}>
              <option value="PUBLISHED">Published</option>
              <option value="DRAFT">Draft</option>
              <option value="ARCHIVED">Archived</option>
            </Select>
          </Label>

          <Checkbox
            {...register("publicProfilePage")}
            label="Make this project public"
          />
        </Stack>
      </Box>

      <FormFooter>
        {isError && (
          <FieldError block>Sorry, something went wrong. Try again.</FieldError>
        )}

        <FlexRow
          justifyContent="space-between"
          flexWrap="nowrap"
          gap="1em 5px"
          style={{ width: "100%" }}
        >
          {!data ? (
            <div />
          ) : (
            <Link
              variant="secondary"
              size="sm"
              to={`/artists/${project?.id ?? data?.id}`}
              style={{ margin: 0 }}
            >
              {isDirty ? "Cancel" : "View Profile"}
            </Link>
          )}

          <Button type="submit" variant="primary" disabled={isProjectUpdating}>
            {isEdit ? "Save" : `Submit Project ${(<IconArrow />)}`}
          </Button>
        </FlexRow>
      </FormFooter>
      {isProjectUpdating && <LoadingSpinner />}
    </SForm>
  );
}

const SForm = styled(Form)(({ theme }) => ({
  gap: "3rem",
  gridArea: "b",
  maxWidth: "750px",
  margin: "0 auto",
  h2: {
    ...theme.fonts.body,
    fontWeight: "bold",
  },
}));

const ReturnLink = styled(Link)({
  margin: "2rem 0",
});

export { ProjectForm };
