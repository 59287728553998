import { useQuery } from "react-query";
import type { User } from "features/auth";
import { api } from "utils/api";

const postDiscordConnect = (tokenType: string, accessToken: string | null) =>
  accessToken
    ? api.post<User>(`/profile/discord`, {
        body: JSON.stringify({ tokenType, accessToken }),
      })
    : Promise.reject(new Error("Invalid discord access token"));

const useDiscordConnect = (tokenType: string, accessToken: string | null) =>
  useQuery(
    ["discordConnect", tokenType, accessToken],
    () => postDiscordConnect(tokenType, accessToken),
    { enabled: !!accessToken },
  );

type DiscordResponse = {
  username: string;
  discriminator: string;
};

export type { DiscordResponse };

export { useDiscordConnect };
