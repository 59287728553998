import * as React from "react";
import { Helmet } from "react-helmet-async";
import { constants } from "config";

const createVerificationToken = async (
  action: string,
  cb: (token: string) => void,
) => {
  // ReCaptcha client might exist but still be loading, wrap with ready() to queue the call
  window.grecaptcha.enterprise.ready(async () => {
    // Generate a score-based token
    const token = await window.grecaptcha?.enterprise.execute(
      // Using dev or prod site key based on envvars
      constants.recaptchaKey,
      // And labeled by the associated user interaction
      { action },
    );

    if (typeof token !== "string") {
      throw new Error(`ReCaptcha token is unknown type: ${typeof token}`);
    }

    // Run the callback to provide the generated token
    cb(token);
  });
};

const ReCaptchaContext = React.createContext<typeof createVerificationToken>(
  createVerificationToken,
);

function ReCaptchaProvider({ children }: { children?: React.ReactNode }) {
  return (
    <ReCaptchaContext.Provider value={createVerificationToken}>
      <Helmet>
        <script
          async
          src={`https://www.google.com/recaptcha/enterprise.js?render=${constants.recaptchaKey}`}
        />
      </Helmet>

      {children}
    </ReCaptchaContext.Provider>
  );
}

const useReCaptcha = () => {
  const context = React.useContext(ReCaptchaContext);
  if (!context) {
    throw new Error(
      "useReCaptcha() may be used only within the context of a <ReCaptchaProvider> component",
    );
  }
  return context;
};

export { ReCaptchaProvider, useReCaptcha };
