import styled from "@emotion/styled";
import { formatWalletAddress } from "utils/wallet";

type WalletProps = {
  wallets: string[];
};

function WalletList({ wallets, ...props }: WalletProps) {
  return (
    <StyledWallets {...props}>
      {wallets.map((wallet) => (
        <StyledWallet key={wallet}>{formatWalletAddress(wallet)}</StyledWallet>
      ))}
    </StyledWallets>
  );
}

const StyledWallets = styled.ul({
  listStyleType: "none",
  margin: 0,
  padding: 0,
  marginBottom: 0,
  li: {
    marginBottom: ".25rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    "&::marker": {
      overflow: "auto",
    },
  },
  "li::before": {
    content: '"\\200B"',
  },
});

const StyledWallet = styled.li(({ theme }) => ({
  fontSize: theme.fontSizes.xxs,
}));

export { WalletList };
