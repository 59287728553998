import * as React from "react";
import styled from "@emotion/styled";
import ReactCrop, { Crop, PixelCrop, PercentCrop } from "react-image-crop";
import { Button, Callout, FieldError, FlexRow, Img, Text } from "atoms";
import { canvasPreview } from "../utils/canvasPreview";
import { centerCrop } from "../utils/centerCrop";
import "react-image-crop/dist/ReactCrop.css";

type ImageCropperProps = {
  aspect: number;
  file: File;
  onCancel: () => void;
  onSubmit: (croppedFile: File) => void;
};

function ImageCropper({ aspect, file, onCancel, onSubmit }: ImageCropperProps) {
  const [src, setSrc] = React.useState("");
  const [crop, setCrop] = React.useState<Crop>();
  const [complete, setComplete] = React.useState<PixelCrop>();
  const [error, setError] = React.useState(false);

  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const imgRef = React.useRef<HTMLImageElement>(null);

  // When file changes, setup a new file reader to create a src string
  React.useEffect(() => {
    // Reset cropped file, if exists
    setCrop(undefined);

    // Read selected file and, on load, create a data url to use as img src
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setSrc(reader.result?.toString() ?? "");
    });
    reader.readAsDataURL(file);
  }, [file]);

  // When completed crop changes, update a preview canvas element with cropped result
  React.useEffect(() => {
    const updatePreview = async () => {
      if (complete && imgRef.current && canvasRef.current) {
        canvasPreview(imgRef.current, canvasRef.current, complete, 1, 0);
      }

      // Show the required warning if the user de-selected the crop rectangle
      if (Math.min(complete?.width ?? 0, complete?.height ?? 0) < 10) {
        setError(true);
      } else {
        setError(false);
      }
    };

    // Debounce the effect so we don't redraw the canvas on every pixel change
    const timeout = setTimeout(updatePreview, 200);
    return () => clearTimeout(timeout);
  }, [complete]);

  /**
   * Set default crop once image is selected
   */
  const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = event.currentTarget;
    setCrop(centerCrop(width, height, aspect || 1));
  };

  /**
   * Update crop dimensions on resize and move actions
   */
  const handleCrop = (_: PixelCrop, percent: PercentCrop) => setCrop(percent);
  const handleComplete = (pixel: PixelCrop) => setComplete(pixel);

  /**
   * Reset cropper to initial state and call parent's onCancel method
   */
  const handleReset = () => {
    setSrc("");
    setError(false);
    setCrop(undefined);
    setComplete(undefined);
    onCancel();
  };

  const handleSubmit = () => {
    const { name, type } = file;
    canvasRef.current?.toBlob((blob) => {
      if (blob instanceof Blob) {
        const croppedFile = new File([blob], name, { type });
        onSubmit(croppedFile);
      }
    }, type);
  };

  return (
    <SCallout>
      {!!src && (
        <>
          <Scrim />
          <CropWrapper>
            <Text size="md" bold>
              Crop and Center Image
            </Text>

            <ReactCrop
              aspect={aspect}
              crop={crop}
              onChange={handleCrop}
              onComplete={handleComplete}
            >
              <Img
                ref={imgRef}
                alt="Crop your uploaded file"
                src={src}
                onLoad={handleImageLoad}
              />
            </ReactCrop>

            <FlexRow justifyContent="space-between">
              <SButton variant="blank" onClick={handleReset} size="xxs">
                Cancel
              </SButton>

              {error ? (
                <FieldError>You must select a crop</FieldError>
              ) : (
                <SButton
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={error}
                >
                  Crop Image
                </SButton>
              )}
            </FlexRow>
          </CropWrapper>
        </>
      )}

      {complete && <HiddenCanvas ref={canvasRef} />}
    </SCallout>
  );
}

const SCallout = styled(Callout)(({ theme }) => ({
  backgroundColor: theme.colors.bg,
  padding: 0,
  display: "inline-block",
  maxWidth: "100%",
  "@media (min-width: 600px)": {
    padding: 0,
  },
  ".ReactCrop__crop-selection": {
    // border: `2px dashed ${theme.colors.fg20}`,
    border: "none",
  },
  "&>div": {
    justifyContent: "flex-start",
  },
}));

const Scrim = styled.div({
  position: "fixed",
  inset: 0,
  backgroundColor: "rgb(0 0 0 / .33)",
  zIndex: 599,
});

const CropWrapper = styled.div(({ theme }) => ({
  alignItems: "flex-start",
  display: "flex",
  gap: "1rem",
  flexDirection: "column",
  justifyContent: "space-around",
  margin: 0,
  maxHeight: "calc(100vh - 2rem)",
  maxWidth: "calc(100vw - 2rem)",
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.colors.bg,
  borderRadius: theme.borderRadius.md,
  boxShadow: theme.boxShadow.dark,
  padding: "1rem",
  width: 600,
  zIndex: 600,

  img: {
    maxHeight: "70vh",
  },
  "& p": { marginBottom: 0 },
  "& > div:last-child": {
    width: "100%",
  },
}));

const SButton = styled(Button)(({ theme }) => ({
  fontSize: theme.fontSizes.xs,
  "&&": { minHeight: 0 },
}));

const HiddenCanvas = styled.canvas({
  clip: "rect(1px, 1px, 1px, 1px)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  width: 1,
});

export { ImageCropper, HiddenCanvas };
