import * as React from "react";
import styled from "@emotion/styled";
import {
  Button,
  FlexRow,
  LoadingSpinner,
  Stack,
  Text,
  UnexpectedError,
} from "atoms";
import { ProductWizardStep } from "../ProductWizardStep";
import { ProductDetails } from "../ProductDetails";
import type { StepProps } from "../../types";
import { useUpdateProductDetails } from "../../api/useUpdateProductDetails";
import { useProductAdminQuery } from "../../api/useProductAdminQuery";

function StepConfirmation({ dispatch, state, moveBack }: StepProps) {
  const {
    mutate,
    isLoading: isPublishing,
    isError,
    error,
  } = useUpdateProductDetails(state.product.id);

  const isDraft = state.product.status !== "ACTIVE";

  const moveNext = () => dispatch({ type: "moveNext" });

  const onPublish = () => mutate({ status: "ACTIVE" }, { onSuccess: moveNext });

  const {
    refetch,
    isError: isErrorProduct,
    error: errorProduct,
  } = useProductAdminQuery(state.product.id);

  const onRefresh = () => {
    const timer = setTimeout(() => {
      clearTimeout(timer);
      refetch();
    }, 1000);
  };

  return (
    <ProductWizardStep
      stepTitle="Preview Product Listing"
      state={state}
      moveBack={moveBack}
      previewNext={moveNext}
      publishProduct={onPublish}
      isUnpublished={isDraft}
    >
      {isPublishing && <LoadingSpinner text="Publishing" />}

      <Stack gap="md">
        <Stack gap="xxs">
          {state.product.productType === "physical item" && (
            <FlexRow flexWrap="wrap" gap="6px 1em" alignItems="flex-start">
              <Text size="xs" style={{ flex: "1 0 420px", margin: 0 }}>
                The preview may take a few moments to update.{" "}
                <TextButton onClick={onRefresh} variant="text">
                  Refresh the preview
                </TextButton>{" "}
                if the information below is not accurate.
              </Text>
              <Button variant="secondary" size="xxs" onClick={onRefresh}>
                Refresh Preview
              </Button>
            </FlexRow>
          )}
        </Stack>
        <ProductDetails
          artistId={state.artistId}
          productId={state.product.id}
          isPreview
        />

        {isError && <UnexpectedError error={error} />}
        {isErrorProduct && <UnexpectedError error={errorProduct} />}
      </Stack>
    </ProductWizardStep>
  );
}

StepConfirmation.displayName = "Preview";

const TextButton = styled(Button)(({ theme }) => ({
  fontSize: theme.fontSizes.xs,
  letterSpacing: 0,
}));

export { StepConfirmation };
