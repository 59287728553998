import { useQuery } from "react-query";
import { api } from "utils/api";
import type { Project } from "../types";

const getProjectById = (projectId: string) =>
  api.get<Project>(`/projects/${projectId}`);

const useProject = (projectId: string) =>
  useQuery(["PROJECT", projectId], () => getProjectById(projectId));

export { useProject, getProjectById };
