// Node environment (isProd=True on Staging )
const env = process.env.NODE_ENV || "development";

// Dev Mode - Running Local
const isDev = env === "development";

// Test Mode - Running Tests
const isTest = env === "test";

// Node ENV - Running on Staging or Production
// This should be the same as (isStaging || isProduction)
const isNodeProductionBuild = env === "production";

// HUG Environment
const hostName = new URL(process.env.REACT_APP_API_URL ?? "").host;
const isProduction = hostName === "thehug.xyz" || hostName === "api.thehug.xyz";
const isStaging = isNodeProductionBuild && !isProduction;

const getEnvName = () => {
  if (isProduction) {
    return "production";
  }
  if (isStaging) {
    return "staging";
  }
  return "development";
};
const envName = getEnvName();

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? "";

// Feature flags
const featureFlags = {
  // Catch outgoing requests with MSW or pass through to actual API_URL, only in DEV
  enableAPIMocking: !isNodeProductionBuild && false,
  // Require staging password authentication to entire site
  enableStagingAuth: !!process.env.REACT_APP_SHOW_PASSWORD,
  // Show React-Query developer tool icon in bottom-left corner
  enableQueryDevTools: isDev,
  // Allow Rewards Wallet Connect
  enableSBTRewards: false,
  // Show new artist profile
  enableBetaProfile: true,
  // Enable Instagram Import
  enableInstagramImport: !!process.env.REACT_APP_ENABLE_INSTAGRAM,
  // Enable Wallet Import
  enableWalletImport: !!process.env.REACT_APP_ENABLE_WALLET,
};

// Constants
const constants = {
  commitHash: process.env.COMMIT_HASH ?? "",

  // API and WebSocket URLs
  apiUrl: process.env.REACT_APP_API_URL ?? "",
  wsUrl: process.env.REACT_APP_WS_URL ?? "",
  // Time to reconnect after drop
  wsTimeout: 1000 * 30,

  // Staging Access cookie details
  stagingPreviewKey: "HUG_STAGING_PREVIEW",
  stagingPreviewAge: 60 * 60 * 24 * 7,

  // Application Links
  groupHugApplication: "https://thehugxyz.typeform.com/grouphug0003",
  hedgeHugApplication: "https://thehugxyz.typeform.com/hedgehug",
  innovationLabURL: "https://lab.thehug.xyz",
  hugStudiosURL: "https://studios.thehug.xyz",
  hugArtPrints: "https://shop.thehug.xyz/collections/prints",
  hugHolidayShop: "https://shop.thehug.xyz/collections/holiday",
  hugShopWaitList: "https://go.thehug.xyz/storewaitlist",

  // Social Links
  twitterURL: "https://twitter.com/thehugxyz",
  discordURL: "https://discord.gg/thehugxyz",
  instagramURL: "https://www.instagram.com/thehugxyz/",
  mediumArticle:
    "https://medium.com/@thehugxyz/hug-it-out-with-us-2ae5a97b1d3c",
  openseaURL: "https://opensea.io/collection/hugpass",
  openseaMetaAngels: "https://opensea.io/collection/metaangelsnft",

  // Misc HUG Links
  collectiveRequestURL: "https://go.thehug.xyz/collectiverequest",
  innoLabResourcesURL: "https://go.thehug.xyz/innolabresources",

  // Web3
  alchemyApiKey: process.env.REACT_APP_ALCHEMY_API_KEY ?? "",
  alchemyUrl: process.env.REACT_APP_ALCHEMY_URL ?? "",
  chainId: 1,
  contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS ?? "",
  etherscanURL: "https://etherscan.io",
  walletConnectProjectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID ?? "",
  okxWalletWebpage: "https://www.okx.com/web3",
  coinbaseWalletWebpage: "https://www.coinbase.com/wallet",
  metamaskWalletWebpage: "https://metamask.io/download",
  rainbowWalletWebpage: "https://rainbow.me",

  // Stripe
  stripePublicKey,
  stripeTestEnv: !stripePublicKey || stripePublicKey.startsWith("pk_test_"),

  // Shopify Storefront API
  shopifyDomain: process.env.REACT_APP_SHOPIFY_DOMAIN ?? "",
  shopifyAccessToken: process.env.REACT_APP_SHOPIFY_API_PUBLIC_TOKEN ?? "",

  // Oauth
  discordConnectUrl: process.env.REACT_APP_DISCORD_CONNECT_URL ?? "",
  instagramClientId: process.env.REACT_APP_IG_CLIENT_ID ?? "",

  // Google ReCaptcha Keys
  recaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY ?? "",

  // Rollbar
  rollbarKey:
    process.env.REACT_APP_ROLLBAR_KEY ?? "fd1768791a4b4cefaf67ea2b614a1246",

  // MixPanel (Staging Token Hard Coded)
  mixPanelToken:
    process.env.REACT_APP_MIXPANEL_TOKEN || "f74e3eb7c12d509b3e630b39c0e6b772",

  // Studios
  studiosUrl: "https://studios.thehug.xyz",
  galleryPartnersUrl: "https://www.studios.thehug.xyz/gallery-partners",

  // contact info
  supportEmail: "hello@thehug.xyz",
  partnershipEmail: "partnerships@thehug.xyz",

  // newsletter urls
  weeklyHugURL: "https://hug.beehiiv.com/",
  weeklyHugSubscribeURL: "https://hug.beehiiv.com/subscribe",
  creatorRoyaltiesURL: "https://creatorroyalties.beehiiv.com/",
  creatorRoyaltiesSubscribeURL:
    "https://creatorroyalties.beehiiv.com/subscribe",

  // Points Earned
  pointsEarnedReviewApproved: 100,
  pointsEarnedFirst10ReviewApproved: 150,
  pointsEarnedModeratorVote: 10,
  pointsEarnedApplicationVote: 5,
  pointsEarnedEmailVerification: 100,
  pointsEarnedOnboarding: 150,
  pointsEarnedInviteApplies: 30,
  pointsEarnedInviteApproved: 70,

  // Senior Curator
  reviewsForSeniorCurator: 25,

  // Points Staked
  pointReviewCost: 50,

  maxPortfolioItems: 200,

  // 100mb = mb * kb * b
  maxArtworkUploadSize: 100 * 1000 * 1000,

  // Default HUG Shop Fee
  defaultShopFee: 0.06,
};

export {
  env,
  envName,
  isDev,
  isProduction,
  isNodeProductionBuild,
  isTest,
  isStaging,
  featureFlags,
  constants,
};
