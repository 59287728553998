import { useQuery } from "react-query";
import { api } from "utils/api";
import type { RewardToken } from "../types";
import { rewardKeys } from "./queryKeys";

/**
 * Fetch a user's RewardTokens
 */
const getProfileRewards = (userId: string) =>
  api.get<RewardToken[]>(`/users/${userId}/tokens`);

const useRewards = (userId: string) =>
  useQuery(rewardKeys.detail(userId), () => getProfileRewards(userId), {
    enabled: !!userId,
  });

export { rewardKeys, useRewards };
