import { FeaturedItemGrid, LoadingEllipses, UnexpectedError } from "atoms";
import { useCollectionQuery } from "../api/useCollectionQuery";
import { ProductCard } from "./ProductCard";

type FeaturedProductsProps = {
  artistId: string;
  excluding?: string;
};

function FeaturedProducts({ artistId, excluding }: FeaturedProductsProps) {
  const { data, error, isError } = useCollectionQuery({
    handle: artistId,
    numProducts: 5,
  });

  if (data) {
    return (
      <FeaturedItemGrid>
        {data.products
          .filter((product) => product.id !== excluding)
          .slice(0, 4)
          .map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
      </FeaturedItemGrid>
    );
  }

  if (isError) {
    return <UnexpectedError error={error} />;
  }

  return <LoadingEllipses />;
}

export { FeaturedProducts };
