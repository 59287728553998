import * as React from "react";
import styled from "@emotion/styled";
import {
  IconReactionChefsKiss,
  IconReactionMindBlowing,
  IconReactionMustHug,
  IconReactionMustHugWhiteBg,
  IconReactionNeedItNow,
  IconReactionObsessed,
  IconReactionOutOfThisWorld,
  IconReactionTakeMyMoney,
  IconReactionThoughtProvoking,
  IconReactionYayWhiteBg,
  IconReactionNahWhiteBg,
  IconReactionMehWhiteBg,
} from "atoms";
import type { Artist, Project } from "../types";

type TopReactionsProps = {
  entity?: Artist | Project;
};

const iconList: Record<string, typeof IconReactionMustHug> = {
  Yay: IconReactionYayWhiteBg,
  Nah: IconReactionNahWhiteBg,
  Meh: IconReactionMehWhiteBg,
  MustHug: IconReactionMustHugWhiteBg,
  ChefsKiss: IconReactionChefsKiss,
  MindBlowing: IconReactionMindBlowing,
  NeedItNow: IconReactionNeedItNow,
  Obsessed: IconReactionObsessed,
  OutOfThisWorld: IconReactionOutOfThisWorld,
  TakeMyMoney: IconReactionTakeMyMoney,
  ThoughtProvoking: IconReactionThoughtProvoking,
};

function TopReactions({ entity }: TopReactionsProps) {
  // Get all counterReaction properties from entity, sort in descending order, take top three,
  // convert to rendered Icon component, filter out incorrect icons, then reverse for layer effect
  const topReactions = React.useMemo(
    () =>
      !entity
        ? []
        : Object.entries(entity)
            .filter(
              ([key, val]) =>
                key.startsWith("counter") && typeof val === "number" && val > 0,
            )
            .sort((a, b) => b[1] - a[1])
            .slice(0, 3)
            .map(([key]) => ({
              key,
              Icon: iconList[key.replace("counter", "")],
            }))
            .filter(({ Icon }) => !!Icon)
            .reverse(),
    [entity],
  );

  if (!entity || entity.reviewCount <= 0) {
    return null;
  }

  return (
    <ReactionList>
      {topReactions.map(({ key, Icon }) => (
        <IconWrapper key={key}>
          <Icon />
        </IconWrapper>
      ))}
    </ReactionList>
  );
}
const ReactionList = styled.div({
  display: "flex",
});
const IconWrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.bg,
  borderRadius: theme.borderRadius.round,
  boxShadow: theme.boxShadow.light,
  display: "flex",
  alignitems: "center",
  justifyContent: "center",
  height: "1.75em",
  overflow: "hidden",
  width: "1.75em",
  position: "relative",
  zIndex: 2,
  marginLeft: "-1.1em",
  svg: {
    width: "calc(100% + 4px)",
    height: "auto",
    margin: "-2px",
  },
  "&:first-of-type": { zindex: 3, order: 99 },
  "&:last-of-type": { zindex: 1, order: -1, marginLeft: 0 },
}));

export { TopReactions, IconWrapper };
