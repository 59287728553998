import styled from "@emotion/styled";

type ImageProps = {
  aspectRatio?: React.CSSProperties["aspectRatio"];
  entityType?: "ARTIST" | "PROJECT";
};

const Img = styled.img<ImageProps>(({ aspectRatio, entityType, theme }) => ({
  aspectRatio,
  display: "block",
  objectFit: "cover",
  width: "100%",
  maxWidth: "100%",
  position: "relative",
  ...(entityType && {
    borderRadius:
      entityType === "ARTIST"
        ? theme.borderRadius.round
        : theme.borderRadius.xs,
  }),
}));

export { Img };
