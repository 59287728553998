import styled from "@emotion/styled";
import { List } from "atoms";
import type { ReviewableAffiliation } from "../types";
import { ReviewableCard } from "./ReviewableCard";

interface AffiliatedCollectionProps {
  affiliations: ReviewableAffiliation[];
}

function AffiliatedCollections({ affiliations }: AffiliatedCollectionProps) {
  return (
    <SAffiliatedCollections type="blank">
      {affiliations.map(({ image, ...affiliation }) => (
        <li key={affiliation.id}>
          <ReviewableCard {...affiliation} heroImage={image} />
        </li>
      ))}
    </SAffiliatedCollections>
  );
}

const SAffiliatedCollections = styled(List)({
  display: "grid",
  fontWeight: "bold",
  gap: "1rem",
  gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
  lineHeight: 1.3,
  li: {
    "&::before": {
      position: "absolute",
    },
  },
});

export { AffiliatedCollections };
