import * as React from "react";
import styled from "@emotion/styled";
import {
  Button,
  CopyToClipboard,
  DropdownMenu,
  IconShare,
  IconTwitter,
  Link,
  List,
  Stack,
  Text,
} from "atoms";
import { trackShareTweet } from "contexts/mixpanel";
import { Artist, Project } from "../types";

interface ShareMenuProps {
  reviewable: Artist | Project;
  isShareMenuOpen: boolean;
  toggleShareMenu: () => void;
}

function ShareMenu({
  reviewable,
  isShareMenuOpen,
  toggleShareMenu,
}: ShareMenuProps) {
  const [isCopyUrlSuccess, setIsCopyUrlSuccess] = React.useState(false);

  const isProject = reviewable.type === "PROJECT";
  const profileUrl = `https://thehug.xyz/${
    isProject ? "projects" : "artists"
  }/${reviewable.id}`;

  const tweetText = `Check out ${
    reviewable.name
  } on @thehugxyz! 🤗 thehug.xyz/${isProject ? "projects" : "artists"}/${
    reviewable.id
  }`;
  const tweetUrl = `https://twitter.com/intent/tweet?text=${tweetText}`;

  React.useEffect(() => {
    if (isCopyUrlSuccess === true) {
      const timeout = setTimeout(() => {
        setIsCopyUrlSuccess(false);
      }, 1000 * 6);
      return () => clearTimeout(timeout);
    }
    return noop;
  }, [isCopyUrlSuccess]);

  const onClickTweet = () => {
    toggleShareMenu();
    trackShareTweet();
  };

  return (
    <ShareMenuWrapper>
      <ShareMenuToggle
        type="button"
        size="xxxs"
        onClick={toggleShareMenu}
        aria-label={`${isShareMenuOpen ? "Close" : "Open"} share menu`}
        aria-controls="#share"
        aria-expanded={isShareMenuOpen}
        variant="secondary"
      >
        <IconShare aria-hidden="true" />
      </ShareMenuToggle>

      <ShareMenuDropdown isOpen={isShareMenuOpen} id="share">
        <Stack gap="sm">
          <Text bold size="xxxs" textTransform="uppercase">
            Share this profile
          </Text>
          <List type="blank">
            <Stack gap="0">
              <div>
                <li>
                  <CopyToClipboard
                    buttonText="Copy Link"
                    value={profileUrl}
                    sendShareEvent
                    onClick={toggleShareMenu}
                  />
                </li>
              </div>
              <div>
                <li>
                  <SLink
                    href={tweetUrl}
                    type="button"
                    variant="blank"
                    onClick={onClickTweet}
                    target="_blank"
                  >
                    <IconTwitter />
                    Tweet
                  </SLink>
                </li>
              </div>
            </Stack>
          </List>
        </Stack>
      </ShareMenuDropdown>
    </ShareMenuWrapper>
  );
}

const ShareMenuWrapper = styled.div({
  right: 0,
  display: "block",
  position: "relative",
  width: "min-content",
});

interface WithIsOpen {
  isOpen: boolean;
}

const ShareMenuToggle = styled(Button)({
  alignItems: "center",
  display: "block",
  justifyContent: "center",
  paddingLeft: 10,
  paddingRight: 10,
  lineHeight: "1em",
  svg: {
    pointerEvents: "none",
    fontSize: "1em",
    transform: "scale(1.2)",
  },
});

const ShareMenuDropdown = styled(DropdownMenu)<WithIsOpen>({
  maxWidth: "200px",
});

const SLink = styled(Link)({
  letterSpacing: 0,
  textTransform: "none",
});

export { ShareMenu };
