import styled from "@emotion/styled";
import { Button, H2, Link, Text, Stack, FlexRow, IconChevron } from "atoms";
import { formatDate } from "utils/date";
import { useToggle } from "utils/hooks";
import { useArtistApplication } from "../api/useArtistApplications";
import type { Artist } from "../types";
import { ArtistApplicationWorkflowStatus } from "./ArtistApplicationWorkflowStatus";

function AdminArtistProfileInfo({ entity }: { entity: Artist }) {
  const rawApplicationId = entity.applicationId;
  const parsedApplicationId = rawApplicationId?.includes("~")
    ? rawApplicationId.split("~")?.[1]
    : rawApplicationId;
  const { data } = useArtistApplication(parsedApplicationId);
  const application = data?.application;

  const [isOpen, toggle] = useToggle(false);

  return (
    <InfoBox>
      <Stack gap="xs" style={{ padding: "1rem", flex: "0 1 0%" }}>
        <FlexRow justifyContent="space-between" gap="0 1rem">
          <H2 size="xs" style={{ margin: 0 }}>
            Profile&nbsp;Info
          </H2>
          <ToggleButton size="xxxs" variant="secondary" onClick={toggle}>
            <span>{isOpen ? "Hide info" : "Show info"}</span>
            <IconChevron rotate={isOpen ? 0 : 180} />
          </ToggleButton>
        </FlexRow>
        <Text size="xxxxs" as="small">
          <em>Visible&nbsp;to admins&nbsp;only</em>
        </Text>
      </Stack>

      <InfoCollapse isOpen={isOpen}>
        <SStack gap="0">
          <Text size="xxs" bold>
            Status
          </Text>
          <Text size="xxxs">{entity.status}</Text>
        </SStack>

        <SStack gap="0">
          {entity.adminUsers?.length ? (
            <Stack gap="0">
              <Text size="xxs" bold>
                Claims
              </Text>
              {entity.adminUsers.map((id) => (
                <Text size="xxxs" key={id}>
                  <Link to={`/admin/users/${id}`}>
                    {id.substring(0, 6)}...{id.slice(-6)}
                  </Link>
                </Text>
              ))}
            </Stack>
          ) : (
            <Text size="xxxs">No claims</Text>
          )}
          <div>
            <Link to="admin" size="xxs">
              <strong>Manage Claims</strong>
            </Link>
          </div>
        </SStack>

        {application && (
          <SStack gap="0">
            <Text size="xxs" bold>
              Applications
            </Text>
            <Text size="xxxs" bold>
              <Link to={`/admin/applications/${application.id}`}>
                View Artist Application
              </Link>
            </Text>
            {application && (
              <ArtistApplicationWorkflowStatus app={data?.application} />
            )}
          </SStack>
        )}

        <SStack gap="0">
          <Text size="xxs" bold>
            Claim Reminders
          </Text>

          <Text size="xxxs">
            {entity.acceptedReminder1EmailSentAt ? (
              <>
                1:{" "}
                {formatDate(entity.acceptedReminder1EmailSentAt, {
                  dateStyle: "medium",
                })}
              </>
            ) : (
              "None sent"
            )}
          </Text>
          {entity.acceptedReminder1EmailSentAt && (
            <Text size="xxxs">
              <>
                2:{" "}
                {formatDate(entity.acceptedReminder1EmailSentAt, {
                  dateStyle: "medium",
                })}
              </>
            </Text>
          )}
        </SStack>
      </InfoCollapse>
    </InfoBox>
  );
}

const InfoBox = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  backgroundColor: theme.colors.fg05,
  borderRadius: theme.borderRadius.sm,
  overflow: "hidden",
  "&>*": {
    width: "100%",
  },
  [theme.breakpoints.desktopLarge]: {
    flexDirection: "row",
    "&>*": {
      width: "auto",
    },
  },
}));
const ToggleButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.desktopLarge]: {
    display: "none",
  },
}));

const InfoCollapse = styled.div<{ isOpen: boolean }>(({ isOpen, theme }) => ({
  display: isOpen ? "flex" : "none",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: 0,
  [theme.breakpoints.desktopLarge]: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-around",
  },
}));

const SStack = styled(Stack)(({ theme }) => ({
  borderTop: `1px solid ${theme.colors.fg20}`,
  borderBottom: `1px solid ${theme.colors.fg20}`,
  marginBottom: -1,
  padding: "1rem",
  flex: "1 0 100%",
  [theme.breakpoints.xs]: {
    flex: "0 0 50%",
    "&:nth-of-type(even)": {
      borderLeft: `1px solid ${theme.colors.fg20}`,
    },
    "&:nth-of-type(odd):last-of-type": {
      borderRight: `1px solid ${theme.colors.fg20}`,
      flex: "0 0 calc(50% + 1px)",
    },
  },
  [theme.breakpoints.desktop]: {
    flex: "0 0 33.333%",
    "&:nth-of-type(even)": { borderLeftWidth: 0 },
    "&:nth-of-type(3n), &:nth-of-type(3n - 1)": {
      borderLeft: `1px solid ${theme.colors.fg20}`,
    },
    "&:nth-of-type(even):last-of-type": {
      borderRight: 0,
      flex: "0 0 33.333%",
    },
    "&:nth-of-type(3n - 1):last-of-type,&:nth-of-type(3n - 2):last-of-type": {
      borderRight: `1px solid ${theme.colors.fg20}`,
      flex: "0 0 calc(33.333% + 1px)",
    },
  },
  [theme.breakpoints.desktopLarge]: {
    borderTop: 0,
    borderBottom: 0,
    marginBottom: 0,
    "&&&&": {
      borderLeft: `1px solid ${theme.colors.fg20}`,
      borderRight: 0,
      flex: "1 0 auto",
    },
  },
  "p:first-of-type": {
    color: theme.colors.fg,
  },
}));

export { AdminArtistProfileInfo };
