import { useForm } from "react-hook-form";
import { useReCaptcha } from "contexts";
import { api } from "utils/api";
import { useAsync } from "utils/hooks";

type RequestCommissionFormFields = {
  email: string;
  twitterHandle: string;
  contactPreference: string;
  reasonForCommission: string;
};

// POST request
const requestCommission = (
  artistId: string,
  formData: RequestCommissionFormFields,
  reCaptchaToken: string,
) =>
  api.post<RequestCommissionFormFields>(
    `/artists/${artistId}/request-commission`,
    {
      body: JSON.stringify({
        artistCommissionFields: formData,
        reCaptchaToken,
      }),
    },
  );

const useRequestCommissionForm = (
  artistId: string,
  defaultValues: Pick<RequestCommissionFormFields, "email" | "twitterHandle">,
) => {
  const createVerificationToken = useReCaptcha();

  const { handleSubmit, ...form } = useForm<RequestCommissionFormFields>({
    defaultValues: {
      contactPreference: "email",
      ...defaultValues,
    },
  });

  const { run, ...rest } = useAsync<RequestCommissionFormFields>();

  // Validate form fields, on success generate a recaptcha token and post to API
  const onSubmit = handleSubmit((formData) =>
    createVerificationToken("FORM_ARTIST_COMMISSION", (token) => {
      run(requestCommission(artistId, formData, token));
    }),
  );

  return {
    onSubmit,
    ...form,
    ...rest,
  };
};

export { useRequestCommissionForm };
