import * as React from "react";
import styled from "@emotion/styled";
import { Theme } from "@emotion/react";
import { hexToRGB, preventForwarding } from "styles/styleUtils";
import { reviewTagCSS } from "./ReviewTag";

const Label = styled.label(({ theme }) => ({
  ...theme.fonts.body,
  alignItems: "flex-start",
  cursor: "pointer",
  display: "flex",
  fontWeight: "400",
  gap: theme.spacing.sm,
  userSelect: "none",
  "& + &": {
    marginTop: theme.spacing.md,
  },
  "&:has([disabled])": {
    cursor: "not-allowed",
    "input + *": {
      opacity: 0.6,
    },
  },
}));

const borderWidth = 2;

const CheckboxControl = styled.input(({ theme }) => ({
  ...theme.fonts.body,
  appearance: "none",
  background: "rgba(0,0,0,0)",
  border: `${borderWidth}px solid currentColor`,
  borderRadius: theme.borderRadius.xs,
  color: "currentcolor",
  cursor: "pointer",
  display: "grid",
  font: "inherit",
  height: "1.25em",
  margin: 0,
  minHeight: "1.25em",
  minWidth: "1.25em",
  placeContent: "center",
  width: "1.25em",
  "&::before": {
    content: "''",
    // Fallback for high-contrast mode
    backgroundColor: "CanvasText",
    // Better results than background which might be removed (e.g. printing)
    boxShadow: `inset .9em .9em ${theme.colors.bg}`,
    // For the checkmark shape
    clipPath: "polygon(43% 71%, 84% 14%, 95% 24%, 43% 95%, 11% 54%, 23% 45%)",
    height: "1em",
    // Start hidden, only visible when [checked]
    opacity: 0,
    transition: "opacity 0.2s ease",
    transform: "scale(1.1)",
    transformOrigin: "bottom",
    width: "1em",
  },
  "&:checked": {
    backgroundColor: theme.colors.fg,
    borderColor: theme.colors.fg,
    "&::before": {
      opacity: 1,
    },
  },

  "&::after": {
    content: "''",
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-30px 0 0 -30px",
    width: 60,
    height: 60,
    borderRadius: theme.borderRadius.md,
    opacity: 0,
    pointerEvents: "none",
  },
  "&:checked::after": {
    boxShadow: `inset 0 0 0 30px ${hexToRGB(theme.colors.bg, 0)}`,
  },
  "&[disabled]": {
    opacity: 0.6,
    cursor: "not-allowed",
  },
}));

type CheckboxProps = {
  /**
   * Required string or JSX component to label the checkbox
   */
  label: React.ReactNode;
  // NOTE: ESlint has a bug with forwardRef that prevents types from working correctly when nested
  // or intersected and passed via the generics arguments, so we have to explicitly type each arg.
  // See: https://github.com/jsx-eslint/eslint-plugin-react/issues/3140
  className?: string;
} & React.ComponentProps<typeof CheckboxControl>;

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, className, ...props }, ref) => (
    <Label className={className}>
      <CheckboxControl type="checkbox" {...props} ref={ref} />
      <div>{label}</div>
    </Label>
  ),
);

type TagCheckboxVariants = {
  [key: string]: (theme: Theme) => [string, string];
};

/**
 * Each variant returns a `[borderColor, background]` pair from the Theme
 */
const tagCheckboxVariants: TagCheckboxVariants = {
  default: (theme) => [theme.colors.fg60, theme.colors.fg10],
  founder: (theme) => [theme.colors.fg, theme.colors.accent3LL],
  community: (theme) => [theme.colors.fg, theme.colors.accent2LL],
  roadmap: (theme) => [theme.colors.fg, theme.colors.accent4LL],
  art: (theme) => [theme.colors.fg, theme.colors.accent1LL],
  founderAttribute: (theme) => [theme.colors.fg, theme.colors.accent2LL],
  missionOrCause: (theme) => [theme.colors.fg, theme.colors.accent3LL],
  medium: (theme) => [theme.colors.fg, theme.colors.accent1LL],
};

type TagCheckboxProps = {
  variant: keyof typeof tagCheckboxVariants;
};

const TagCheckbox = styled(Checkbox, {
  shouldForwardProp: preventForwarding<TagCheckboxProps>("variant"),
})<TagCheckboxProps>(({ variant, theme }) => {
  const [borderColor, background] = tagCheckboxVariants[variant](theme);

  return {
    ...reviewTagCSS(theme),
    ...theme.fonts.body,
    border: "0",
    fontSize: 14,
    margin: ".5rem .5rem .5rem 0",
    paddingTop: ".2em",
    paddingBottom: ".15em",
    paddingRight: "1.25em",
    position: "relative",
    gap: 0,
    // overflow: "hidden",
    "&+&": {
      marginTop: 0,
    },
    "&:hover input": {
      transform: "none",
    },
    input: {
      background: "rgb(0 0 0 / 0)",
      border: 0,
      borderRadius: 0,
      width: "1.25em",
      height: "1.25em",
    },
    "input:checked:focus::after": {
      border: `1px solid ${theme.colors.fg}`,
    },
    "input::before": {
      width: "14px",
      height: "14px",
      pointerEvents: "none",
      transform: "translate(0, -1px)",
      transitionProperty: "opacity, transform",
      zIndex: 9,
    },
    "input:checked": {
      background: "rgb(0 0 0 / 0)",
    },
    "input:checked::before": {
      boxShadow: "none",
      transform: "translate(3px, -1px)",
    },
    "input::after": {
      all: "initial",
      animation: "none",
      backgroundColor: "rgb(0 0 0 / 0)",
      bottom: "0",
      border: "1px solid",
      borderColor,
      borderRadius: theme.borderRadius.round,
      content: "''",
      left: "0",
      margin: 0,
      position: "absolute",
      right: "0",
      top: "0",
      pointerEvents: "none",
      transition: "all 0.15s ease",
    },
    "input:focus::after, input:focus-visible::after": {
      borderColor: theme.colors.fg,
      borderWidth: 2,
    },
    "input:focus, input:focus-visible": {
      outline: "none",
    },
    "input:hover::after": {
      borderColor,
    },
    "input:checked::after, input:checked:focus::after": {
      animation: "none",
      background,
      borderColor,
      color: theme.colors.fg,
      borderWidth: 2,
      // opacity: .66,
    },
    div: {
      ...theme.fonts.body,
      pointerEvents: "none",
      position: "relative",
      transition: "transform 0.15s ease",
      zIndex: 2,
    },
    "input:checked+div": {
      transform: "translateX(4px)",
    },
  };
});

export { Checkbox, TagCheckbox, CheckboxControl };
