import * as React from "react";
import type { FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";
import styled from "@emotion/styled";
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";
import { CharacterCounter } from "./CharacterCounter";

const EDITOR_EMPTY_VAL = "<p><br></p>";
const EDITOR_CLASS = ".ql-editor";

type UseRichTextEditorParams<T extends FieldValues> = Pick<
  UseControllerProps<T>,
  "name" | "control"
> & {
  placeholder?: string;
  /**
   * Mark field as invalid during validation if empty
   * This includes the rich text equivalent of empty, `"<p><br></p>"`, requiring at least one other
   * character to be valid.
   */
  required?: boolean;
  /**
   * Mark field as invalid during validation if text length is more than this number
   * This does NOT include HTML tags or attributes, only pure text content length
   */
  maxLength?: number;
  /**
   * Mark field as invalid during validation if text length is less than this number
   * This does NOT include HTML tags or attributes, only pure text content length
   */
  minLength?: number;
};

const useRichTextEditor = <T extends FieldValues>({
  name,
  control,
  placeholder,
  required,
  maxLength,
  minLength,
}: UseRichTextEditorParams<T>) => {
  // State for Quill instance
  const [quill, setQuill] = React.useState<Quill>();

  // Register field with useForm's control, adding any validation rules
  const {
    field: { onBlur, onChange, ref: fieldRef, value },
  } = useController<T>({
    name,
    control,
    rules: {
      ...(required
        ? {
            required: `This field is required`,
          }
        : {}),
      ...(maxLength
        ? {
            validate: () =>
              (quill?.getText().length ?? 0) <= maxLength ||
              `This field is limited to ${maxLength} characters`,
          }
        : {}),
      ...(minLength
        ? {
            validate: () =>
              (quill?.getText().length ?? 0) >= minLength ||
              `This field requires at least ${minLength} characters`,
          }
        : {}),
    },
  });

  // Create a callback ref for the RichTextEditor component to run ONCE on render
  const ref = React.useCallback((element: HTMLDivElement) => {
    if (!element) {
      return;
    }

    // If field already has a value, then it was given as a defaultValue to useForm. Insert that
    // value into the editor element before it's initialized
    if (value) {
      element.innerHTML = value;
    }

    // Create a new configured Quill instance for this editor component
    const quillInstance = new Quill(element, {
      formats: ["bold", "italic", "link", "list"],
      modules: {
        toolbar: [
          ["bold", "italic"],
          ["link"],
          [{ list: "bullet" }, { list: "ordered" }],
        ],
      },
      theme: "bubble",
      placeholder,
    });
    setQuill(quillInstance);

    // Now that Quill has instantiated, there should be injected elements within our RichTextEditor
    // div including the actual contenteditable element that can be programmatically focused on just
    // like any other input element. Find it and pass it to useController's callback ref so that
    // useForm can focus the editor on validation errors, falling back to our node just in case.
    const editableNode = element.querySelector<HTMLDivElement>(EDITOR_CLASS);
    fieldRef(editableNode ?? element);

    // Configure Quill's tooltip placeholder values
    const tooltipInput = element.querySelector<HTMLInputElement>(
      ".ql-tooltip-editor input[data-link]",
    );
    if (tooltipInput) {
      tooltipInput.dataset.link = "https://thehug.xyz";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Interacting with the toolbar buttons or entering data into the tooltip editor (e.g. links)
  // does not trigger the editor's onInput event, so we need to progromatically dispatch it.
  React.useEffect(() => {
    // Use the quill editor's parent (i.e. our RichTextEditor div) to find our elements
    const toolbar =
      quill?.root.parentElement?.querySelector<HTMLDivElement>(".ql-toolbar");
    const tooltipInput =
      quill?.root.parentElement?.querySelector<HTMLInputElement>(
        ".ql-tooltip-editor input",
      );

    // In case quill isn't instantiated yet, don't do anything
    if (!quill || !toolbar || !tooltipInput) {
      return noop;
    }

    // Create our event listener for both of the above elements
    const forceInputEvent = (event: MouseEvent | KeyboardEvent) => {
      // We need to run on all clicks and when the user hits enter key
      if ("key" in event && event.key !== "Enter") {
        return;
      }

      // Create our fake input event and trigger it on our quill editor element
      const inputEvent = new Event("input", { bubbles: true });
      quill.root.dispatchEvent(inputEvent);
    };

    toolbar.addEventListener("click", forceInputEvent);
    tooltipInput.addEventListener("keydown", forceInputEvent);

    return () => {
      toolbar.removeEventListener("click", forceInputEvent);
      tooltipInput.removeEventListener("keydown", forceInputEvent);
    };
  }, [quill]);

  // NOTE: Quill uses a <div> with contenteditable attribute which doesn't fire any onChange event
  // for react-hook-form to pick up on, set values, or run validation. A contenteditable element
  // will fire an `input` event though, so we can listen here and set the form field's value which
  // covers keyboard input and some clipboard events.
  const onInput: React.FormEventHandler<HTMLDivElement> = () => {
    // Get HTML markup as string from quill editor element
    let html = quill?.root.innerHTML;

    // Check if the editor is empty, this can happen during input events on clipboard cuts. If so,
    // set value to empty string in react-hook-form to trigger any required validation on form
    // submit. See onKeyDown for similar check on different event.
    if (html === EDITOR_EMPTY_VAL) {
      html = "";
    }

    onChange(html);
  };

  // NOTE: Handle paste events separately because they don't trigger above input event. This will
  // manually run the above handler after the paste event has _hopefully_ been handled by inserting
  // whatever text from the clipboard into the DOM. We might have to bump this timeout up if it
  // continues failing.
  const onPaste: React.ClipboardEventHandler<HTMLDivElement> = (event) => {
    setTimeout(() => {
      onInput(event);
    }, 10);
  };

  // NOTE: Backspace and delete key presses don't trigger the above onInput event if the resulting
  // value is an empty input, NOR do hotkeys like `cmd-b` for formatting the selection so a special
  // case needs to be added to check for these key presses. If the result is an empty editor, update
  // react-hook-form with new empty string for any validation on form submit.
  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (
      (event.key === "Backspace" || event.key === "Delete") &&
      quill?.root.innerHTML === EDITOR_EMPTY_VAL
    ) {
      onChange("");
    } else if (
      (event.ctrlKey || event.metaKey) &&
      ["b", "i"].includes(event.key)
    ) {
      // Checks for Windows and Mac keyboard shortcuts for bolding and italicizing text
      onInput(event);
    }
  };

  return {
    quill,
    editorProps: {
      name,
      ref,
      onBlur,
      onInput,
      onKeyDown,
      onPaste,
    },
  };
};

type RichTextEditorProps<T extends FieldValues> = React.ComponentProps<
  typeof RichTextEditorWrapper
> &
  UseRichTextEditorParams<T> & { showCharacterCounter?: boolean };

function RichTextEditor<T extends FieldValues>({
  control,
  name,
  placeholder,
  required,
  maxLength,
  minLength,
  showCharacterCounter,
  ...props
}: RichTextEditorProps<T>) {
  const { editorProps, quill } = useRichTextEditor({
    control,
    name,
    placeholder,
    required,
    maxLength,
    minLength,
  });

  return (
    <div>
      <RichTextEditorWrapper {...props} {...editorProps} />
      {showCharacterCounter && (
        <CharacterCounter
          min={minLength}
          max={maxLength}
          value={quill?.getText() ?? ""}
        />
      )}
    </div>
  );
}

const RichTextEditorWrapper = styled.div(({ theme }) => ({
  [EDITOR_CLASS]: {
    background: theme.colors.bg,
    border: `1px solid ${theme.colors.fg30}`,
    borderBottomColor: theme.colors.fg60,
    color: theme.colors.fg,
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: 1.3,
    margin: "0.5rem 0 0",
    padding: "1rem 1.2rem 1.8rem",
  },
  // Placeholder text
  [`${EDITOR_CLASS}::before`]: {
    left: "1.2rem",
  },
}));

type RichTextProps = {
  value?: string;
  className?: string;
};

function RichText({ value = "", className }: RichTextProps) {
  return (
    <ReadOnly
      dangerouslySetInnerHTML={{ __html: value }}
      className={className}
    />
  );
}

const ReadOnly = styled.div(({ theme }) => ({
  color: theme.colors.bodyColor,
  fontSize: theme.fontSizes.xs,
  // prevents bad paragraph spacing due to lack of soft returns in editor
  p: { margin: 0 },
  "p:has(br:only-child)+p:has(br:only-child)": {
    display: "none",
  },
  a: {
    color: theme.colors.linkColor,
    textDecoration: "underline",
    "&:hover, &:focus-visible": {
      color: theme.colors.linkHoverColor,
    },
  },
}));

export {
  ReadOnly,
  RichText,
  RichTextEditor,
  RichTextEditorWrapper,
  useRichTextEditor,
};
