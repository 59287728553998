import styled from "@emotion/styled";
import { Box, FlexRow, H3, Link, Stack, Text } from "atoms";
import { Thumbnail } from "features/images";
import { pluralize } from "utils/text";
import { useArtist } from "../api/useArtist";

type OwnedReviewableProps = {
  id: string;
  canEdit?: boolean;
  // type: ReviewableType;
};

/**
 * Small card for user's owned reviewable used on Account Settings
 *
 * NOTE: currently Artist only
 */
function OwnedReviewable({ id, canEdit }: OwnedReviewableProps) {
  const { data } = useArtist(id);

  // Small component on profile, no need to show loading or error state for now
  if (!data) {
    return null;
  }

  return (
    <SBox>
      <FlexRow justifyContent="flex-start" flexWrap="wrap" gap="0.66rem">
        <ThumbnailWrapper aria-hidden="true">
          <Thumbnail
            src={data.heroImage}
            entityType={data.type}
            width="60"
            height="60"
          />
        </ThumbnailWrapper>

        <FlexRow flexWrap="wrap" gap=".5rem">
          <Stack gap="xxs" style={{ flex: "1 1 220px" }}>
            <H3 size="sm">
              <Link to={`/artists/${id}`}>{data.name}</Link>
            </H3>

            <GrayText size="xxxs" as="div">
              <FlexRow gap="0 1em">
                <span>{pluralize(data.reviewCount, "Review")}</span>
                <span>{pluralize(data.followerCount, "Follower")}</span>
              </FlexRow>
            </GrayText>
          </Stack>
          {canEdit && (
            <Link to={`/artists/${id}/edit`} variant="secondary" size="xxs">
              Edit Profile
            </Link>
          )}
        </FlexRow>
      </FlexRow>
    </SBox>
  );
}

const SBox = styled(Box)({
  padding: "1rem",
});

const ThumbnailWrapper = styled.div({
  width: 60,
  height: 60,
  flex: "0 0 60px",
  alignSelf: "flex-start",
  "&+*": {
    flex: 1,
  },
});

const GrayText = styled(Text)(({ theme }) => ({
  color: theme.colors.fg80,
  lineHeight: 1.2,
}));

export { OwnedReviewable };
