import * as React from "react";
import { useSocketMessageHandler } from "contexts/socket";
import { User, UserRole } from "features/auth";
import type { RewardLedgerResponse } from "features/profile/types";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { api } from "utils/api";

const getAdminUserRewards = (userId?: string) =>
  userId
    ? api.get<RewardLedgerResponse>(`/admin/users/${userId}/rewards`, {
        requireAuth: true,
      })
    : Promise.reject(new Error("Invalid user id"));

const useAdminRewards = (userId?: string) =>
  useQuery(["admin-user-rewards", userId], () => getAdminUserRewards(userId), {
    enabled: !!userId,
  });

const getAdminUserById = (userId?: string) =>
  userId
    ? api.get<User>(`/admin/users/${userId}`)
    : Promise.reject(new Error("Invalid user id"));

const putAdminUserUpdate = (user: User) => {
  // Filter out any nullish roles/utilities/etc that might make the update fail with 422 status
  const body = {
    ...user,
    roles: user.roles.filter(Boolean),
    utilities: user.utilities.filter(Boolean),
    utilitiesOverride: user.utilitiesOverride.filter(Boolean),
  };

  return api.put<User>(`/admin/users/${user.id}`, {
    body: JSON.stringify(body),
  });
};

const postAdminUserRefresh = (userId?: string) =>
  userId
    ? api.post<User>(`/admin/users/${userId}/refresh`, {
        body: JSON.stringify({}),
      })
    : Promise.reject(new Error("Invalid user id"));

const useAdminUser = (userId?: string) =>
  useQuery(["admin-user", userId], () => getAdminUserById(userId), {
    enabled: !!userId,
  });

type AdminUserSocketMessage = {
  type: "USER";
  user: User;
};

const useAdminUserSocketRefresh = () => {
  const queryClient = useQueryClient();

  const refreshAdminUser = React.useCallback(
    (data: AdminUserSocketMessage) => {
      if (data.type === "USER") {
        queryClient.invalidateQueries(["admin-user", data.user.id]);
      }
    },
    [queryClient],
  );

  useSocketMessageHandler<AdminUserSocketMessage>(
    "NOTIFICATION",
    refreshAdminUser,
  );
};

const useAdminUserRefresh = () => {
  const queryClient = useQueryClient();
  const { mutate: adminRefreshUser } = useMutation(
    (userId?: string) => postAdminUserRefresh(userId),
    {
      onSuccess: (response) =>
        queryClient.invalidateQueries(["admin-user", response.id]),
    },
  );
  return adminRefreshUser;
};

const useAdminUserUpdate = () => {
  const queryClient = useQueryClient();

  const { mutate: updateUser, ...mutation } = useMutation(
    (user: User) => putAdminUserUpdate(user),
    {
      onSuccess: (response) =>
        queryClient.invalidateQueries(["admin-user", response.id]),
    },
  );

  const adminUserAddPlatformRole = (user: User, role: UserRole) => {
    if (!user.roles.includes(role)) {
      return updateUser({ ...user, roles: [...user.roles, role] });
    }
    return null;
  };

  const adminUserRemovePlatformRole = (user: User, role: UserRole) => {
    const roles = user.roles.filter((o) => o !== role);
    return updateUser({ ...user, roles });
  };

  const adminUserAddDiscordOverride = (user: User, role: string) => {
    if (!user.utilitiesOverride.includes(role)) {
      return updateUser({
        ...user,
        utilitiesOverride: [...user.utilitiesOverride, role],
      });
    }
    return null;
  };

  const adminUserRemoveDiscordOverride = (user: User, role: string) => {
    const utilitiesOverride = user.utilitiesOverride.filter((o) => o !== role);
    return updateUser({ ...user, utilitiesOverride });
  };

  const adminUserResetDiscord = (user: User) =>
    updateUser({
      ...user,
      discordId: undefined,
      discord: undefined,
    });

  return {
    updateUser,
    adminUserAddDiscordOverride,
    adminUserRemoveDiscordOverride,
    adminUserAddPlatformRole,
    adminUserRemovePlatformRole,
    adminUserResetDiscord,
    ...mutation,
  };
};

export {
  useAdminUser,
  useAdminUserRefresh,
  useAdminUserSocketRefresh,
  useAdminUserUpdate,
  useAdminRewards,
};
