import artPrintCategory from "assets/images/product-category-art-print.jpg";
import digitalDownloadCategory from "assets/images/product-category-digital-download.jpg";
import journalA4DownloadableTemplate from "assets/productTemplates/HUG-template_journal-A4.png";
import journalA4Template from "assets/productTemplates/product-builder-template_journal-A4.svg";
import notebookTemplate from "assets/productTemplates/product-builder-template_spiral-notebook.svg";
import notebookDownloadableTemplate from "assets/productTemplates/HUG-template_spiral-notebook.png";
import journalEmptyMockup from "assets/productTemplates/product-builder-empty-mockup_journal.png";
import notebookEmptyMockup from "assets/productTemplates/product-builder-empty-spiral-notebook.png";
// import journalA5DownloadableTemplate from "assets/productTemplates/HUG-template_journal-A5.png";
// import journalA5Template from "assets/productTemplates/product-builder-template_journal-A5.png";
import journalCategory from "assets/images/product-category-journal.jpg";
import liveSessionCategory from "assets/images/product-category-live-session.jpg";
import merchandiseCategory from "assets/images/product-category-merchandise.jpg";
import mugCategory from "assets/images/product-category-mug.jpg";
import mugDownloadableTemplate from "assets/productTemplates/HUG-template_11oz-mug.png";
import mugEmptyMockup from "assets/productTemplates/product-builder-empty-mockup_11oz-mug.png";
import mugTemplate from "assets/productTemplates/product-builder-template_11oz-mug.svg";
import notebookCategory from "assets/images/product-category-spiral-notebook.jpg";
import onchainCategory from "assets/images/product-category-onchain-collectible.jpg";
import shipYourselfCategory from "assets/images/product-category-ship-it-yourself.jpg";

import type { ProductTemplateSteps, TemplateCategory } from "../types";
import { CreateStepShipItYourself } from "../components/productWizardSteps/CreateStepShipItYourself";
import { CreateStepUploadArtwork } from "../components/productWizardSteps/CreateStepUploadArtwork";
import { CreateStepDesignPreview } from "../components/productWizardSteps/CreateStepDesignPreview";
import { CreateStepUploadAsset } from "../components/productWizardSteps/CreateStepUploadAsset";
import { StepArtPrintOptions } from "../components/productWizardSteps/StepArtPrintOptions";
import { StepArtPrintPricing } from "../components/productWizardSteps/StepArtPrintPricing";
import { StepSpiralNotebookOptions } from "../components/productWizardSteps/StepSpiralNotebookOptions";
import { StepSpiralNotebookPricing } from "../components/productWizardSteps/StepSpiralNotebookPricing";
import { StepConfirmation } from "../components/productWizardSteps/StepConfirmation";
import { StepDetails } from "../components/productWizardSteps/StepDetails";
import { StepPricing } from "../components/productWizardSteps/StepPricing";
import { StepShipping } from "../components/productWizardSteps/StepShipping";
import { StepProductMedia } from "../components/productWizardSteps/StepProductMedia";
import { StepTokenDetails } from "../components/productWizardSteps/StepTokenDetails";

const templateCategories: {
  name: TemplateCategory;
  description?: string;
  image: string;
}[] = [
  {
    name: "Your Physical Product",
    description: "Ship It Yourself",
    image: shipYourselfCategory,
  },
  {
    name: "Onchain Collectible",
    image: onchainCategory,
  },
  {
    name: "Digital Download",
    image: digitalDownloadCategory,
  },
  {
    name: "Fine Art Print",
    description: "Print on Demand",
    image: artPrintCategory,
  },
  {
    name: "Spiral Notebook",
    description: "Print on Demand",
    image: notebookCategory,
  },
  {
    name: "Hardcover Journal",
    description: "Print on Demand",
    image: journalCategory,
  },
  {
    name: "Ceramic Mug",
    description: "Print on Demand",
    image: mugCategory,
  },
];

const comingSoonTemplates: {
  name: string;
  description?: string;
  image: string;
}[] = [
  {
    name: "More Merchandise",
    image: merchandiseCategory,
    description: "Print on Demand",
  },
  {
    name: "Live Session",
    image: liveSessionCategory,
  },
];

/**
 * Validate product media step to make sure at least one image was added to product
 */
const atLeastOneMedia: ProductTemplateSteps["validate"]["productMedia"] = (
  media,
) => media.length > 0 || "This product needs at least one image.";

const productTemplateSteps: ProductTemplateSteps[] = [
  /**
   * Print on Demand Templates
   */
  {
    id: "PRODIGI-PRINTS",
    name: "Art Print",
    templateCategory: "Fine Art Print",
    CreateStep: CreateStepUploadArtwork,
    steps: [
      StepDetails,
      StepArtPrintOptions,
      StepArtPrintPricing,
      StepConfirmation,
    ],
    validate: {},
  },
  {
    /** Spiral Notebooks
     * source: https://www.prodigi.com/products/stationery/notebooks/
     */
    id: "PRODIGI-NOTEBOOK",
    name: "Spiral Notebook",
    templateCategory: "Spiral Notebook",
    CreateStep: CreateStepDesignPreview,
    createStepOptions: {
      emptyMockupSrc: notebookEmptyMockup,
      designTemplateSrc: notebookTemplate,
      downloadableTemplateSrc: notebookDownloadableTemplate,
      // A4 at 8.25 x 11.75in
      printArea: { width: 2504, height: 3531 },
      // A5 at 5.75 x 8.25in
      // printArea: { width: 1810, height: 2534 },
    },
    steps: [
      StepDetails,
      StepSpiralNotebookOptions,
      StepSpiralNotebookPricing,
      StepConfirmation,
    ],
    validate: {},
  },
  {
    /** Hardcover Journals
     * source: https://www.prodigi.com/products/stationery/journals/
     */
    id: "PRODIGI-JOURNAL",
    name: "Hardcover Journal",
    templateCategory: "Hardcover Journal",
    CreateStep: CreateStepDesignPreview,
    createStepOptions: {
      designPreviewInstructions: (
        <>
          Your artwork should fill the entire print area.
          <br />
          Keep important elements within the safe area to prevent them from
          being cropped during production
        </>
      ),
      designTemplateSrc: journalA4Template,
      downloadableTemplateSrc: journalA4DownloadableTemplate,
      emptyMockupSrc: journalEmptyMockup,
      // A4 at 8.25 x 11.75in
      printArea: { width: 5256, height: 3673 },
      // A5 at 5.75 x 8.25in
      // printArea: { width: 3780, height: 2646 },
    },
    steps: [StepDetails, StepSpiralNotebookPricing, StepConfirmation],
    validate: {},
  },
  {
    /** Ceramic Mug
     * source: https://www.prodigi.com/products/home-and-living/drinkware/photo-mugs/ ???
     */
    id: "PRODIGI-MUG",
    name: "Ceramic Mug",
    templateCategory: "Ceramic Mug",
    CreateStep: CreateStepDesignPreview,
    createStepOptions: {
      designTemplateSrc: mugTemplate,
      downloadableTemplateSrc: mugDownloadableTemplate,
      emptyMockupSrc: mugEmptyMockup,
      printArea: { width: 2670, height: 1110 },
    },
    steps: [StepDetails, StepSpiralNotebookPricing, StepConfirmation],
    validate: {},
  },

  /**
   * Onchain Asset Templates
   */
  {
    id: "HUG-ON-CHAIN",
    name: "HUG Contract",
    templateCategory: "Onchain Collectible",
    CreateStep: CreateStepUploadArtwork,
    steps: [
      StepDetails,
      StepProductMedia,
      StepTokenDetails,
      StepPricing,
      StepConfirmation,
    ],
    validate: {
      productMedia: (media) =>
        media.length > 0 || "This product needs at least one image.",
    },
  },

  /**
   * Digital Downloads
   */
  {
    id: "HUG-DIGITAL-DOWNLOADS",
    name: "Digital Download",
    templateCategory: "Digital Download",
    CreateStep: CreateStepUploadAsset,
    steps: [StepDetails, StepProductMedia, StepPricing, StepConfirmation],
    validate: {
      productMedia: atLeastOneMedia,
    },
  },

  /**
   * Ship It Yourtself
   */
  {
    id: "ARTIST-SHIPS",
    name: "Ship It Yourself",
    templateCategory: "Your Physical Product",
    CreateStep: CreateStepShipItYourself,
    steps: [
      StepDetails,
      StepProductMedia,
      StepShipping,
      StepPricing,
      StepConfirmation,
    ],
    validate: {
      productMedia: atLeastOneMedia,
    },
  },
];

export { productTemplateSteps, templateCategories, comingSoonTemplates };
