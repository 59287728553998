import { api } from "utils/api";
import { useQueryClient, useMutation } from "react-query";
import { useForm } from "react-hook-form";
import type { Artist, ArtistFormFields } from "../types";
import { createDefaultArtist } from "../utils/createReviewable";
import { artistKeys } from "./queryKeys";

/**
 * Merge Artist form fields with defaults from placeholder data
 */
const withDefaults = (formData: ArtistFormFields) => ({
  ...createDefaultArtist(formData.id),
  ...formData,
  status: "PUBLISHED",
});

/**
 * API call to create new Artist
 */
const createArtist = (formData: ArtistFormFields) =>
  api.post<Artist>(`/artists/create`, {
    body: JSON.stringify(withDefaults(formData)),
  });

/**
 * Custom hook to create new Artist and manage state
 */
const useCreateArtist = () => useMutation(createArtist);

/**
 * API call to update existing Artist
 */
const updateArtist = async (updates: Artist) =>
  api.put<Artist>(`/artists/${updates.id}`, {
    body: JSON.stringify(updates),
  });

/**
 * Custom hook to update existing Artist and manage state
 */
const useUpdateArtist = () => {
  const queryClient = useQueryClient();
  return useMutation(updateArtist, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(artistKeys.detail(data.id));
    },
  });
};

/**
 * Custom hook for create/edit Artist form
 */
const useArtistForm = (artist?: Artist) => {
  const artistWithUpdatedLinks = artist
    ? {
        ...artist,
        links: artist.links
          .filter(({ url }) => !!url)
          .map(({ url, type }) => ({
            url,
            type: normalizeLinkType(type),
          })),
      }
    : undefined;

  const { handleSubmit, reset, ...rest } = useForm<ArtistFormFields>({
    defaultValues: artistWithUpdatedLinks,
  });

  // Run both create/edit hooks to create mutate functions and state management
  const { mutate: create, ...createMutation } = useCreateArtist();
  const { mutate: update, ...updateMutation } = useUpdateArtist();

  // If we've been given an existing Artist, then use update mutation. Otherwise, use create mutation.
  const isEdit = !!artist;
  const mutation = isEdit ? updateMutation : createMutation;

  // Create form submit handler that can handle either update or create mutate calls
  const onSubmit = handleSubmit(
    ({
      medium,
      founderAttributes,
      artistCollections,
      bannerImage,
      affiliations,
      ...formData
    }) => {
      const transformedData = {
        ...formData,
        // Fieldset of checkboxes with no selection have a value of `false`, convert to empty array
        medium: medium || [],
        founderAttributes: founderAttributes || [],
        affiliations: affiliations || [],
        // Filter out empty links on each artist collection
        artistCollections:
          artistCollections?.map((coll) => ({
            ...coll,
            collectionLinks: coll.collectionLinks.filter(({ url }) => !!url),
          })) ?? [],
        // Banner images are optional, so set undefined if URL is an empty string
        bannerImage: bannerImage?.url === "" ? undefined : bannerImage,
      };

      if (isEdit) {
        update(
          { ...artist, ...transformedData },
          {
            onSuccess: (returnedArtist) => reset(returnedArtist),
          },
        );
      } else {
        create(transformedData, {
          onSuccess: (returnedArtist) => reset(returnedArtist),
        });
      }
    },
  );

  return {
    ...rest,
    isEdit,
    mutation,
    onSubmit,
  };
};

const normalizeLinkType = (type: string): string => {
  switch (type) {
    case "website":
    case "instagram":
    case "twitter":
    case "youtube":
    case "tiktok":
    case "linkedin":
    case "foundation":
    case "openSea":
    case "objkt":
    case "other":
      return type;
    case "personal website":
      return "website";
    default:
      return "other";
  }
};

export { useCreateArtist, useArtistForm, useUpdateArtist };
