import {
  Button,
  AppError,
  FieldError,
  Fieldset,
  FlexRow,
  Form,
  H2,
  Input,
  Label,
  Legend,
  Link,
  Radio,
  RequiredText,
  Text,
  TextArea,
  AllCaps,
} from "atoms";
import { useAuth } from "features/auth";
import { ReCaptchaProvider, withProvider } from "contexts";
import { fieldOptions, validators } from "utils/form";
import { useRequestCommissionForm } from "../api/useRequestCommissionForm";
import type { Artist } from "../types";

interface RequestCommissionProps {
  artist: Artist;
  close: () => void;
}

function RequestCommission({ artist, close }: RequestCommissionProps) {
  const { user } = useAuth();

  const {
    formState: { errors },
    onSubmit,
    register,
    watch,
    isLoading,
    isSuccess,
    isError,
  } = useRequestCommissionForm(artist.id, {
    email: user?.email ?? "",
    twitterHandle: user?.twitter ?? "",
  });

  const contactPreference = watch("contactPreference");

  const artistHasTwitter = !!artist?.links.find(
    (link) => link.type === "twitter",
  );

  return isSuccess ? (
    <>
      <H2 size="md" as="h3" textTransform="uppercase">
        Commission Request Sent!
      </H2>

      <Text size="xs">
        A message has been sent to {artist.name} regarding your commission
        request.
      </Text>

      <Button size="sm" variant="secondary" onClick={close}>
        Close
      </Button>
    </>
  ) : (
    <Form onSubmit={onSubmit}>
      <div>
        <H2 size="lg" as="h3" textTransform="uppercase">
          Request a Commission
        </H2>

        <Text size="xs">
          We&apos;re happy to connect you with {artist.name} to discuss a
          commission. Please share some information about your request in order
          to help the artist respond.
        </Text>
      </div>

      <Label>
        <Text size="xs" as="div" bold style={{ margin: 0 }}>
          Describe what you are looking for in this commission
          <RequiredText />
          <br />
          <small>Include timeline and budget if applicable.</small>
        </Text>

        <TextArea
          {...register("reasonForCommission", {
            ...fieldOptions.required,
          })}
          autoFocus
        />

        {errors?.reasonForCommission && (
          <FieldError>{errors?.reasonForCommission?.message}</FieldError>
        )}
      </Label>

      {artistHasTwitter && (
        <Fieldset>
          <Legend>
            <Text size="xs" as="div" bold style={{ margin: 0 }}>
              How would you like {artist.name} to contact you?
              <RequiredText />
            </Text>
          </Legend>

          <FlexRow style={{ marginBottom: "-1rem" }}>
            <Radio
              label="Email"
              value="email"
              {...register("contactPreference")}
            />

            <Radio
              label="Twitter"
              value="twitter"
              {...register("contactPreference")}
            />
          </FlexRow>
        </Fieldset>
      )}

      {contactPreference === "email" && (
        <Label>
          <Text size="xs" as="div" bold style={{ margin: 0 }}>
            Email address
          </Text>

          <Input
            style={{ maxWidth: "40ch" }}
            {...register("email", {
              validate: (value) =>
                contactPreference === "email"
                  ? validators.validEmail(value)
                  : true,
            })}
          />

          {errors?.email && <FieldError>{errors?.email?.message}</FieldError>}
        </Label>
      )}

      {contactPreference === "twitter" && (
        <Label>
          <Text size="xs" as="div" bold style={{ margin: 0 }}>
            Twitter handle
          </Text>

          <Input
            type="text"
            style={{ maxWidth: "40ch" }}
            {...register("twitterHandle", {
              validate: (value) =>
                contactPreference === "twitter"
                  ? validators.isNotEmpty(value)
                  : true,
            })}
          />

          {errors?.twitterHandle && (
            <FieldError>{errors?.twitterHandle?.message}</FieldError>
          )}
        </Label>
      )}

      <div>
        <Text size="xxxs">
          Submitting this form is a request for contact, and does not guarantee
          a response.
        </Text>

        <FlexRow>
          <Button size="sm" variant="secondary" onClick={close}>
            Cancel
          </Button>

          <Button
            size="sm"
            variant="primary"
            type="submit"
            disabled={isLoading}
            arrow="r"
          >
            Submit Request
          </Button>
        </FlexRow>

        {isError && (
          <AppError>Sorry, something went wrong. Try again.</AppError>
        )}

        <br />
        <br />
        <Text size="xxxs">
          <AllCaps>Hug</AllCaps> provides a connection between collectors and
          artists, bears no responsibility for details between the two parties
          in regards to commission requests including, but not limited to:
          request terms, delivery method, delivery date, medium, cost, and
          huggability. This site is protected by reCAPTCHA Enterprise and the
          Google{" "}
          <Link href="https://policies.google.com/privacy">Privacy Policy</Link>{" "}
          and{" "}
          <Link href="https://policies.google.com/terms">Terms of Service</Link>{" "}
          apply.
        </Text>
      </div>
    </Form>
  );
}

const Wrapped = withProvider(ReCaptchaProvider)(RequestCommission);

export { Wrapped as RequestCommission };
