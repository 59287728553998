import { useMemo } from "react";
import styled from "@emotion/styled";
import badge_curator_1 from "assets/images/badge-hugger.png";
import badge_curator_2 from "assets/images/badge-jr-curator.png";
import badge_curator_3 from "assets/images/badge-curator.png";
import review_10 from "assets/images/achievement_10-reviews.png";
import review_25 from "assets/images/achievement_25-reviews.png";
import review_50 from "assets/images/achievement_50-reviews.png";
import review_100 from "assets/images/achievement_100-reviews.png";
import review_250 from "assets/images/achievement_250-reviews.png";
import { H2, H3, Link, Text, WalletList } from "atoms";
import { isStaging } from "config";
import { api } from "utils/api";
import { useRewards } from "../api/useRewards";
import { User } from "../../auth";
import { RewardToken } from "../types";

const rewardBadges: Record<string, { name: string; src: string }> = {
  "CURATOR-1": {
    name: "Hugger",
    src: badge_curator_1,
  },
  "CURATOR-2": {
    name: "Jr. Curator",
    src: badge_curator_2,
  },
  "CURATOR-3": {
    name: "Curator",
    src: badge_curator_3,
  },
  "REVIEW-10": {
    name: "10 Reviews",
    src: review_10,
  },
  "REVIEW-25": {
    name: "25 Reviews",
    src: review_25,
  },
  "REVIEW-50": {
    name: "50 Reviews",
    src: review_50,
  },
  "REVIEW-100": {
    name: "100 Reviews",
    src: review_100,
  },
  "REVIEW-250": {
    name: "250 Reviews",
    src: review_250,
  },
};

type Props = {
  user: User;
};

function ProfileRewards({ user }: Props) {
  const { data: userRewards } = useRewards(user.id);

  // Ensure the UI knows about the badge
  const filteredRewards = useMemo(
    () =>
      userRewards?.filter(
        (r: RewardToken) =>
          r?.token?.milestone && rewardBadges[r.token.milestone],
      ),
    [userRewards],
  );

  const redirectURL = `https://sbt.the${
    isStaging ? "secret" : ""
  }hug.xyz?token=${api.getToken()}`;

  const hasConnected = user.rewardWallets.length > 0;

  return (
    <>
      <SH2 size="xs">Reward Tokens</SH2>

      <RewardList>
        {filteredRewards?.map((reward) =>
          reward.token ? (
            <RewardItem
              key={reward.token.rewardId}
              order={reward.token.rewardId}
              status={reward.status}
            >
              <img
                height="150"
                width="150"
                src={rewardBadges[reward.token.milestone].src}
                alt=""
              />
              <H3 size="xs">{rewardBadges[reward.token.milestone]?.name}</H3>
              {reward.status === "CLAIMED" ? (
                <ClaimedText size="xxs">Reward Claimed</ClaimedText>
              ) : (
                hasConnected && (
                  <Link size="xs" variant="secondary" href={redirectURL}>
                    Claim Reward
                  </Link>
                )
              )}
            </RewardItem>
          ) : null,
        )}
      </RewardList>

      {hasConnected ? (
        <SText as="div" size="xxs">
          <strong>Your Reward Wallet:</strong>
          <WalletList wallets={user.rewardWallets} />
        </SText>
      ) : (
        <>
          <SText size="xs">
            You haven&apos;t connected a wallet for your HUG Rewards yet!
          </SText>

          <Link size="xs" variant="secondary" href={redirectURL}>
            Connect a Rewards Wallet
          </Link>
        </>
      )}
    </>
  );
}

const SH2 = styled(H2)(({ theme }) => ({
  ...theme.fonts.body,
  fontWeight: "bold",
  margin: 0,
}));

const RewardList = styled.ul({
  display: "flex",
  flexWrap: "wrap",
  gap: 10,
  listStyleType: "none",
  margin: "1.5rem 0",
  padding: 0,
});

const RewardItem = styled.li<{ status?: string; order: number }>(
  ({ order, status, theme }) => ({
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: "1rem 10px 10px 10px",
    borderRadius: theme.borderRadius.md,
    boxShadow: theme.boxShadow.light,
    order,
    "& h3": {
      margin: "0 0 5px 0",
    },
    "& p": {
      margin: 0,
    },
    img: {
      marginBottom: 10,
      ...(status === "EARNED" && {
        filter: "grayscale(0.5)",
        opacity: 0.5,
      }),
    },
    "&::before": {
      content: '"\\200B"',
      position: "absolute",
    },
  }),
);
const ClaimedText = styled(Text)({
  padding: ".5em 0",
});
const SText = styled(Text)({
  marginTop: "1.5rem",
});

export { ProfileRewards };
