import * as React from "react";
import {
  Global,
  ThemeProvider as EmotionProvider,
  Theme,
  useTheme,
} from "@emotion/react";
import { themePrototype } from "../styles/themePrototype";
import { oldBrand } from "../styles/oldBrand";

type ThemeName = "New" | "Old";

const themeMap: Record<ThemeName, Theme> = {
  New: themePrototype,
  Old: oldBrand,
};

type ThemeContextValue = {
  theme: ThemeName;
  setTheme: (theme: ThemeName) => void;
};

const ThemeContext = React.createContext<ThemeContextValue>({
  theme: "New",
  setTheme: noop,
});

function GlobalStyles() {
  const theme = useTheme();

  return <Global styles={theme.global} />;
}

function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [theme, setTheme] = React.useState<ThemeName>("New");

  const value = React.useMemo(
    () => ({
      theme,
      setTheme,
    }),
    [theme, setTheme],
  );

  return (
    <ThemeContext.Provider value={value}>
      <EmotionProvider theme={themeMap[theme]}>
        <GlobalStyles />
        {children}
      </EmotionProvider>
    </ThemeContext.Provider>
  );
}

const useThemeSwitcher = () => {
  const context = React.useContext(ThemeContext);

  if (!context) {
    throw new Error(
      "useThemeSwitcher() may be used only within the context of a <ThemeProvider> component",
    );
  }

  return context;
};

export type { ThemeName };
export { ThemeProvider, useThemeSwitcher };
