import styled from "@emotion/styled";
import { trackEvent } from "contexts/mixpanel";
import {
  Link,
  IconTwitter,
  IconLinkedin,
  IconInstagram,
  IconOpenSea,
  IconDiscord,
  IconTelegram,
  IconFoundation,
  IconMagicEden,
  IconMedium,
  IconReddit,
  IconTikTok,
  IconYouTube,
  IconEtherscan,
  IconAsync,
  IconGlobeAlt,
  Stack,
} from "atoms";
import { ReviewableLink } from "../types";

interface Props {
  name: string;
  link: ReviewableLink;
}

function fixUrl(link: string): string {
  if (link.startsWith("http")) {
    return link;
  }
  return `https://${link}`;
}
function removeHttp(link: string): string {
  return link.replace(/^https?:\/\//, "");
}
function removeWww(link: string): string {
  return link.replace(/^www\./, "");
}

const trackClick = (ButtonContext: string, ButtonDestination: string) => () =>
  trackEvent({
    name: "Button Click",
    ButtonName: "Social Link",
    ButtonContext,
    ButtonDestination,
  });

function SocialLink({ name, link }: Props) {
  const url = fixUrl(link.url).replace(/\/+$/, "");

  const noHttp = removeHttp(url);
  const prettyUrl = removeWww(noHttp);

  let title: string;
  let Icon: typeof IconAsync | typeof IconGlobeAlt;
  let text: string;
  switch (link.type) {
    case "async.art":
      title = `${link.type} link to ${url}`;
      Icon = IconAsync;
      text = "Async Art";
      break;
    case "etherscan":
      title = `${name} on Etherscan`;
      Icon = IconEtherscan;
      text = "Etherscan";
      break;
    case "instagram":
      title = `${name} on Instagram`;
      Icon = IconInstagram;
      text = "Instagram";
      break;
    case "linkedin":
      title = `${name} on LinkedIn`;
      Icon = IconLinkedin;
      text = "LinkedIn";
      break;
    case "openSea":
      title = `${name} on OpenSea`;
      Icon = IconOpenSea;
      text = "OpenSea";
      break;
    case "twitter":
      title = `${name} on Twitter`;
      Icon = IconTwitter;
      text = "Twitter";
      break;
    case "website":
      title = `Link to ${url}`;
      Icon = IconGlobeAlt;
      text = prettyUrl;
      break;
    case "discord":
      title = `${name} on Discord`;
      Icon = IconDiscord;
      text = "Discord";
      break;
    case "telegram":
      title = `Link to the ${name} Telegram`;
      Icon = IconTelegram;
      text = "Telegram";
      break;
    case "foundation":
      title = `Link to the ${name} Foundation`;
      Icon = IconFoundation;
      text = "Foundation";
      break;
    case "magicEden":
      title = `${name} on Magic Eden`;
      Icon = IconMagicEden;
      text = "Magic Eden";
      break;
    case "medium":
      title = `${name} on Medium`;
      Icon = IconMedium;
      text = "Medium";
      break;
    case "reddit":
      title = `${name} on Reddit`;
      Icon = IconReddit;
      text = "Reddit";
      break;
    case "tiktok":
      title = `${name} on TikTok`;
      Icon = IconTikTok;
      text = "TikTok";
      break;
    case "youtube":
      title = `${name} on YouTube`;
      Icon = IconYouTube;
      text = "YouTube";
      break;
    default:
      title = `Link to ${url}`;
      Icon = IconGlobeAlt;
      text = prettyUrl;
      break;
  }

  return (
    <StyledLink
      href={url}
      title={title}
      onClick={trackClick(link.url, link.type)}
    >
      <IconWrapper>
        <Icon aria-hidden="true" accent="fg70" />
      </IconWrapper>
      <LinkText>{text}</LinkText>
    </StyledLink>
  );
}

const StyledLink = styled(Link)({
  alignItems: "center",
  display: "flex",
  flex: "0 0 auto",
  flexWrap: "nowrap",
  gap: ".33em",
  textDecoration: "none",
  maxWidth: "max-content",
});

const LinkText = styled.span(({ theme }) => ({
  fontSize: theme.fontSizes.xs,
  lineHeight: 1,
  overflow: "hidden",
  padding: "2px 0",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));
StyledLink.defaultProps = {
  target: "_blank",
  rel: "noreferrer",
};

const IconWrapper = styled.div(({ theme }) => ({
  border: `2px solid ${theme.colors.fg}`,
  borderRadius: theme.borderRadius.round,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 36,
  minHeight: 36,
  padding: ".25rem",
  width: 36,
  minWidth: 36,
}));

const LinkList = styled(Stack)({
  listStyleType: "none",
  margin: 0,
  padding: 0,
  lineHeight: 1.3,
  "li::before": {
    content: '"\\200B"',
    position: "absolute",
    height: 0,
  },
}).withComponent("ul");
LinkList.defaultProps = {
  gap: "xs",
};

export { SocialLink, LinkList };
