import { api } from "utils/api";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useForm } from "react-hook-form";
import type {
  Project,
  ProjectFormFields,
  ProjectFormFieldsPreTransform,
} from "../types";
import { createDefaultProject } from "../utils/createReviewable";
import { blockchains } from "../data";

//
// Import Project Form Data from Open Sea Slug
// Project is not saved - Only provides some initial data
//

const importProject = (openSeaSlug?: string) =>
  openSeaSlug
    ? api.get<ProjectFormFields>(
        `/api/projects/import?openSeaUrl=${openSeaSlug}`,
      )
    : Promise.reject(new Error("Invalid OpenSea slug"));

const useImportProject = (openSeaSlug?: string) =>
  useQuery(
    ["project", "import", openSeaSlug],
    () => importProject(openSeaSlug),
    {
      enabled: !!openSeaSlug,
    },
  );

/**
 * Merge Project form fields with defaults from placeholder data
 */
const withDefaults = (formData: ProjectFormFields) => ({
  ...createDefaultProject(formData.id),
  ...formData,
  status: "PUBLISHED",
});

/**
 * API call to create new Project
 */
const createProject = (formData: ProjectFormFields) =>
  api.post<Project>(`/projects/create`, {
    body: JSON.stringify(withDefaults(formData)),
  });

/**
 * Custom hook to create new Project and manage state
 */
const useCreateProject = () => useMutation(createProject);

/**
 * API call to update existing Project
 */
const updateProject = async (updates: Project) =>
  api.put<Project>(`/projects/${updates.id}`, {
    body: JSON.stringify(updates),
  });

/**
 * Custom hook to update existing Project and manage state
 */
const useUpdateProject = () => {
  const queryClient = useQueryClient();
  return useMutation(updateProject, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["PROJECT", data.id]);
    },
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// type ProjectFormFieldsWithImage = ProjectFormFields & { imageUpload: any };

/**
 * Transform a Project into expected shape for form fields
 */
const transformProjectToFields = ({
  highlights,
  mintDate,
  ...rest
}: Project): ProjectFormFieldsPreTransform => ({
  ...rest,
  highlights0: highlights[0] ?? "",
  highlights1: highlights[1] ?? "",
  highlights2: highlights[2] ?? "",
  mintDate: mintDate ? mintDate.split("T")[0] : "",
});

/**
 * Transform form fields into valid Project
 */
const transformFieldstoProject = ({
  doxxed,
  founderAttributes,
  highlights0,
  highlights1,
  highlights2,
  mintDate,
  mintStatus,
  missionOrCause,
  roadmapCategories,
  ...rest
}: ProjectFormFieldsPreTransform): ProjectFormFields => {
  let validDate;
  if (mintDate) {
    try {
      // NOTE: mintDate from input is in yyyy-mm-dd format, add time without zone to use local time
      validDate = new Date(`${mintDate}T00:00`).toISOString();
    } catch {
      validDate = undefined;
    }
  }

  return {
    ...rest,
    // Optional doxxed selection returns empty string, so convert to undefined instead
    doxxed: doxxed?.length ? doxxed : undefined,
    // Combine the three highlights inputs into a single array
    highlights: [highlights0, highlights1, highlights2]
      .map((str) => str.trim())
      .filter(Boolean),
    // Convert date input string into ISO string, see above
    mintDate: validDate,
    // Fieldset of checkboxes with no selection have a value of `false`, convert to empty array
    founderAttributes: founderAttributes || [],
    mintStatus: mintStatus || [],
    missionOrCause: missionOrCause || [],
    roadmapCategories: roadmapCategories || [],
  };
};

/**
 * Custom hook for create/edit Project form
 */
const useProjectForm = (project?: Project) => {
  const { handleSubmit, ...rest } = useForm<ProjectFormFieldsPreTransform>({
    defaultValues: project
      ? transformProjectToFields(project)
      : {
          blockchain: blockchains[0],
          affiliations: [],
          team: [],
          links: [],
          badges: [],
        },
  });

  // Run both create/edit hooks to create mutate functions and state management
  const { mutate: create, ...createMutation } = useCreateProject();
  const { mutate: update, ...updateMutation } = useUpdateProject();

  // If we've been given an existing project, then use update mutation. Otherwise, use create mutation.
  const isEdit = !!project;
  const mutation = isEdit ? updateMutation : createMutation;

  // Create form submit handler that can handle either update or create mutate calls
  const onSubmit = handleSubmit((formData) => {
    const transformed = transformFieldstoProject(formData);
    return isEdit
      ? update({ ...project, ...transformed })
      : create(transformed);
  });

  return {
    ...rest,
    isEdit,
    mutation,
    onSubmit,
  };
};

export { useCreateProject, useProjectForm, useImportProject, useUpdateProject };
