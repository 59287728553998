import styled from "@emotion/styled";
import { H3, List } from "atoms";
import type { ReviewableTeamMember } from "../types";
import { LinkList, SocialLink } from "./SocialLink";

interface Props {
  team: ReviewableTeamMember[];
}

function ProjectTeam({ team }: Props) {
  return (
    <SList type="blank">
      {team.map((member) => (
        <Item key={member.teamMemberName}>
          <div>
            <Name>{member.teamMemberName}</Name>
            <small>{member.teamMemberRoles?.join(", ")}</small>
          </div>
          <SocialRow>
            {member.teamMemberLinks.map((link) => (
              <SocialLink
                key={link.type}
                name={member.teamMemberName}
                link={link}
              />
            ))}
          </SocialRow>
        </Item>
      ))}
    </SList>
  );
}

const SList = styled(List)({
  display: "grid",
  gap: "1.5rem 1rem",
  listStyleType: "none",
  margin: "1rem 0 0",
  "@media (min-width: 400px)": {
    gridTemplateColumns: "repeat(auto-fill, minmax(180px, 1fr))",
  },
});

const Item = styled.li(({ theme }) => ({
  borderTop: `1px solid ${theme.colors.fg20}`,
  fontSize: theme.fontSizes.sm,
  marginBottom: "1rem",
  paddingTop: "1em",
  small: {
    display: "block",
    fontSize: theme.fontSizes.xs,
    textTransform: "capitalize",
    fontWeight: "normal",
  },
}));
const Name = styled(H3)(({ theme }) => ({
  fontSize: theme.fontSizes.sm,
  marginBottom: 0,
}));

const SocialRow = styled(LinkList)(() => ({
  marginTop: 10,
}));

export { ProjectTeam };
