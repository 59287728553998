import * as React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { Text } from "./Typography";

const animationTime = 0.3;

type AlertType = "info" | "success" | "warning" | "error";
type AlertProps = {
  /** Sets a length of time that the alert will be visible on page */
  duration?: number;
  /** Sets the severity (color) and of the alert */
  type?: AlertType;
  children: React.ReactNode;
};

function Alert({ duration, children, type = "info", ...props }: AlertProps) {
  const [isShowing, setisShowing] = React.useState(true);

  React.useEffect(() => {
    if (duration) {
      const timeout = setTimeout(() => {
        setisShowing(false);
      }, 1000 * duration);

      return () => clearTimeout(timeout);
    }

    return noop;
  }, [duration]);

  return isShowing ? (
    <AlertElement type={type} {...props}>
      <AlertText as={typeof children === "string" ? Text : "div"}>
        {children}
      </AlertText>
    </AlertElement>
  ) : null;
}

const fadeIn = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

const AlertElement = styled.div<{ type: AlertType }>(({ type, theme }) => ({
  alignItems: "center",
  backgroundColor: theme.colors[`${type}L`],
  border: "2px solid",
  borderColor: theme.colors[type],
  borderRadius: theme.borderRadius.sm,
  "&&": { color: theme.colors.fg },
  display: "flex",
  lineHeight: 1.3,
  maxWidth: `calc(100vw - (${theme.spacing.gutter} * 2))`,
  paddingTop: theme.spacing.md,
  paddingRight: theme.spacing.sm,
  paddingBottom: theme.spacing.md,
  paddingLeft: theme.spacing.sm,
  transition: `${animationTime}s ease opacity`,
  zIndex: 1000,
  animationName: fadeIn,
  animationFillMode: "forwards",
  animationDuration: `${animationTime}s`,
  animationDelay: "0s",
  "&>*": { marginTop: 0, marginBottom: 0 },
  "&>p": { fontWeight: "bold" },
}));

AlertElement.defaultProps = {
  "aria-live": "polite",
};

const AlertText = styled.div({
  width: "auto",
  flex: "0 1 auto",
  margin: 0,
  padding: "0 .5em",
});

export { Alert };
