import styled from "@emotion/styled";
import { Box, FlexRow, H3, H4, Link, Stack, Text } from "atoms";
import { Thumbnail } from "features/images";
import { formatDate } from "utils/date";
import { StatusBadge } from "atoms/EntityBadge";
import type { InvitedArtistApplication } from "../types";

/**
 * Small card for user's owned reviewable used on Account Settings
 *
 * NOTE: currently Artist only
 */
function InvitedArtist({
  image,
  acceptedDate,
  applicationCreatedAt,
  applicationStatus,
  artist,
  artistId,
}: InvitedArtistApplication) {
  return (
    <Box>
      <FlexRow justifyContent="flex-start" flexWrap="wrap" gap="0.66rem">
        <ThumbnailWrapper aria-hidden="true">
          <Thumbnail src={image} entityType="ARTIST" width="60" height="60" />
        </ThumbnailWrapper>

        <FlexRow flexWrap="wrap" gap=".5rem">
          <Stack gap="xxs" style={{ flex: "1 1 220px" }}>
            <H3 size="md">
              {applicationStatus === "ACCEPTED" && artistId ? (
                <Link to={`/artists/${artistId}`}>{artist}</Link>
              ) : (
                artist
              )}
            </H3>

            <GrayText size="xxxs" textTransform="capitalize">
              {applicationStatus === "ACCEPTED" && acceptedDate ? (
                <>
                  Accepted{" "}
                  <time>
                    {formatDate(acceptedDate, {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </time>
                </>
              ) : (
                <>
                  Applied{" "}
                  <time>
                    {formatDate(applicationCreatedAt, {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </time>
                </>
              )}
            </GrayText>
          </Stack>

          <Stack gap="xxs" style={{ minWidth: "4em" }}>
            <H4 size="sm">Status</H4>
            <StatusBadge status={applicationStatus} />
          </Stack>
        </FlexRow>
      </FlexRow>
    </Box>
  );
}

const ThumbnailWrapper = styled.div({
  width: 60,
  height: 60,
  flex: "0 0 60px",
  alignSelf: "flex-start",
  "&+*": {
    flex: 1,
  },
});

const GrayText = styled(Text)(({ theme }) => ({
  color: theme.colors.fg80,
}));

export { InvitedArtist };
