import styled from "@emotion/styled";
import { Button, DropdownMenu, Link, List, Stack, Text } from "atoms";
import { useAuth } from "features/auth";
import { Artist, Project } from "../types";

interface SecondaryMenuProps {
  reviewable: Artist | Project;
  toggleSecondaryMenu: () => void;
  isSecondaryMenuOpen: boolean;
}

function SecondaryMenu({
  toggleSecondaryMenu,
  isSecondaryMenuOpen,
  reviewable,
}: SecondaryMenuProps) {
  const {
    user,
    roles: { isQA, showAdminFeatures },
  } = useAuth();

  const userIsEditor = !!user && reviewable?.adminUsers?.includes(user?.id);
  const canEdit = showAdminFeatures || isQA || userIsEditor;

  // If user selects menu option, the menu should close after event bubbles up
  // const closeSecondaryMenu: React.ReactEventHandler = (event) => {
  //   if (event.currentTarget !== event.target) {
  //     setIsSecondaryMenuOpen(false);
  //   }
  // };

  return (
    <SecondaryMenuWrapper>
      <SecondaryMenuToggle
        type="button"
        size="xxxs"
        onClick={toggleSecondaryMenu}
        aria-label={`${isSecondaryMenuOpen ? "Close" : "Open"} options menu`}
        aria-controls="#options"
        aria-expanded={isSecondaryMenuOpen}
        variant="secondary"
      >
        •••
      </SecondaryMenuToggle>

      <SecondaryMenuDropdown isOpen={isSecondaryMenuOpen} id="options">
        <Stack gap="sm">
          <Text bold size="xxxs" textTransform="uppercase">
            More Options
          </Text>
          <List type="blank">
            {canEdit && (
              <Li>
                <Link
                  to={`/${reviewable.type.toLowerCase()}s/${
                    reviewable.id
                  }/edit`}
                >
                  Edit Profile
                </Link>
              </Li>
            )}
            {reviewable.type === "ARTIST" && showAdminFeatures && (
              <Li>
                <Link to={`/artists/${reviewable.id}/create-update`}>
                  Post an Update
                </Link>
              </Li>
            )}
          </List>
        </Stack>
      </SecondaryMenuDropdown>
    </SecondaryMenuWrapper>
  );
}

const SecondaryMenuWrapper = styled.div({
  right: 0,
  display: "block",
  position: "relative",
  width: "min-content",
});

interface WithIsOpen {
  isOpen: boolean;
}

const SecondaryMenuToggle = styled(Button)({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  whiteSpace: "nowrap",
  letterSpacing: 2,
  paddingLeft: 12,
  paddingRight: 12,
  svg: {
    pointerEvents: "none",
  },
});

const SecondaryMenuDropdown = styled(DropdownMenu)<WithIsOpen>(
  ({ isOpen }) => ({
    display: isOpen ? "flex" : "none",
    minWidth: "200px",
    maxWidth: "285px",
  }),
);

const Li = styled.li({
  "&+&": {
    marginTop: ".25rem",
  },
  "&,& a, & button": { lineHeight: 1.3 },
  button: {
    marginTop: -2,
    textTransform: "none",
  },
});
export { SecondaryMenu };
