import * as React from "react";
import styled from "@emotion/styled";
import type { CartLineInput } from "@shopify/hydrogen-react/storefront-api-types";
import { Money } from "@shopify/hydrogen-react";
import { Checkbox, ExpandContent, FlexRow, Hr, Stack, Text } from "atoms";
import { GenericImage } from "features/images";
import type { UpsellNode } from "../api/useProductQuery";

type ProductUpsellsProps = {
  upsells: UpsellNode[];
  setSelectedUpsells: React.Dispatch<React.SetStateAction<CartLineInput[]>>;
};

function ProductUpsells({ upsells, setSelectedUpsells }: ProductUpsellsProps) {
  if (!upsells.length) {
    return null;
  }

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { merchandiseId } = event.currentTarget.dataset;
    if (!merchandiseId) {
      return;
    }

    setSelectedUpsells((selected) => {
      // If the upsell is already selected, remove it from the list
      if (selected.find((item) => item.merchandiseId === merchandiseId)) {
        return selected.filter((item) => item.merchandiseId !== merchandiseId);
      }
      // Otherwise, add it to the list
      return [...selected, { merchandiseId, quantity: 1 }];
    });
  };

  return (
    <>
      <Hr />
      <Stack>
        {upsells.map(({ id, title, variants, description }) => {
          const variant = variants[0];

          return !variant ? null : (
            <UpsellLabel key={id}>
              <FlexRow gap="12px">
                {variant.image?.url && (
                  <UpsellImage src={variant.image?.url} sizes="60px" />
                )}
                <Stack style={{ flex: 1 }}>
                  <UpsellTitle>Add {title}</UpsellTitle>

                  {description && (
                    <ExpandContent showLabel="Read more" hideLabel="Show less">
                      <Text size="xxs">{description}</Text>
                    </ExpandContent>
                  )}

                  <Checkbox
                    key={id}
                    label={
                      <SText size="xs">
                        + <Money as="span" data={variant.price} />
                      </SText>
                    }
                    data-merchandise-id={variant.id}
                    onChange={onChange}
                  />
                </Stack>
              </FlexRow>
            </UpsellLabel>
          );
        })}
      </Stack>
    </>
  );
}

const UpsellLabel = styled.label(({ theme }) => ({
  cursor: "pointer",
  position: "relative",
  input: {
    backgroundColor: theme.colors.bg,
  },
  "&>*": {
    position: "relative",
    zIndex: 2,
  },
  "&::before": {
    content: "''",
    position: "absolute",
    inset: -10,
    backgroundColor: theme.colors.fg05,
    borderRadius: theme.borderRadius.sm,
    pointerEvents: "none",
    transition: ".2s ease opacity",
    opacity: 0,
    zIndex: 1,
  },
  "&:hover::before": {
    opacity: 1,
  },
}));
const UpsellImage = styled(GenericImage)(({ theme }) => ({
  backgroundColor: theme.colors.bg,
  width: 60,
  height: 60,
  flex: "0 0 60px",
  border: "1px solid",
  borderColor: theme.colors.fg10,
  borderRadius: theme.borderRadius.sm,
  alignSelf: "flex-start",
}));

const UpsellTitle = styled(Text)(({ theme }) => ({
  ...theme.fonts.display,
  fontWeight: "bold",
  lineHeight: 1.2,
  margin: 0,
  color: theme.colors.fg,
  display: "-webkit-box",
  lineClamp: "3",
  overflow: "hidden",
  WebkitLineClamp: "3",
  WebkitBoxOrient: "vertical",
  textOverflow: "ellipsis",
}));
const SText = styled(Text)(({ theme }) => ({
  margin: 0,
  color: theme.colors.fg,
}));

export { ProductUpsells };
