import * as React from "react";
import { createRoot } from "react-dom/client";
import "utils/polyfills";
import { App } from "./App";
// import { reportWebVitals } from "./reportWebVitals";
import { featureFlags } from "./config";
import "./styles/global.css";

if (featureFlags.enableAPIMocking) {
  /* eslint-disable-next-line */
  const { worker } = require("./mocks/browser");
  worker.start();
}

const container = document.getElementById("root");

if (!container) {
  throw new Error("Cannot render without #root element");
}

const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
