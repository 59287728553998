import { useQuery } from "react-query";
import { ApiError } from "utils/api";
import { Project, Artist } from "../types";
import { getArtistById } from "./useArtist";
import { getProjectById } from "./useProject";

const getReviewableById = (reviewableType: string, reviewableId: string) =>
  reviewableType === "PROJECT"
    ? getProjectById(reviewableId)
    : getArtistById(reviewableId);

const useReviewable = (reviewableType: string, reviewableId: string) =>
  useQuery<Project | Artist, ApiError>(
    [reviewableType, reviewableId],
    () => getReviewableById(reviewableType, reviewableId),
    {
      enabled: !!reviewableType && !!reviewableId,
    },
  );

export { useReviewable };
