import * as React from "react";
import styled from "@emotion/styled";
import {
  Link,
  type LinkProps,
  type AnchorProps,
  IconExternalLink,
} from "atoms";
import { trackEvent } from "contexts/mixpanel";

const HeaderLink = styled(Link)(({ theme }) => ({
  ...theme.fonts.display,
  flex: 1,
  textTransform: "uppercase",
  textDecoration: "none",
  display: "flex",
  gap: 6,
  alignItems: "center",
  margin: ".25rem 0",
  maxWidth: "max-content",
  padding: 0,
  border: 0,
  borderWidth: "2px 0 2px 0",
  borderStyle: "solid",
  borderColor: theme.colors.transparent,
  lineHeight: 1.2,
  cursor: "pointer",
  transition: ".2s ease border-color",
  "&:hover, &:focus-visible": {
    borderBottomColor: theme.colors.accent1,
  },
  [theme.breakpoints.maxTablet]: {
    fontSize: 20,
  },
  [theme.breakpoints.tablet]: {
    fontSize: theme.fontSizes.sm,
    margin: ".25rem 0",
    padding: 0,
    "&:hover, &:focus-visible": {
      textDecoration: "none",
    },
  },
  "&:hover, &:focus": {
    color: theme.colors.fg,
  },
  "&::after": {
    content: "none",
  },
}));

type HeaderMenuLinkProps = (LinkProps | AnchorProps) & {
  label: string;
  showExternalLinkIcon?: boolean;
};

function HeaderMenuLink({
  label,
  children,
  showExternalLinkIcon,
  onClick,
  ...props
}: HeaderMenuLinkProps) {
  const trackedOnClick: React.MouseEventHandler<HTMLAnchorElement> = (
    event,
  ) => {
    trackEvent({ name: "Menu Click", MenuName: label });
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <HeaderLink {...props} onClick={trackedOnClick}>
      {children ?? label}{" "}
      {showExternalLinkIcon && "href" in props && props.href && (
        <SIconExternalLink aria-label="opens in a new tab" />
      )}
    </HeaderLink>
  );
}

const SIconExternalLink = styled(IconExternalLink)({
  width: 10,
});

const IndentedHeaderMenuLink = styled(HeaderMenuLink)(({ theme }) => ({
  [theme.breakpoints.maxTablet]: {
    marginLeft: ".75em",
    fontSize: 16,
  },
  [theme.breakpoints.tablet]: {
    fontSize: theme.fontSizes.xs,
    marginLeft: ".75em",
  },
}));

export { HeaderMenuLink, IndentedHeaderMenuLink };
