import styled from "@emotion/styled";

type TagProps = {
  bg?: "founder" | "mission" | "medium" | string;
};

const Tag = styled.span<TagProps>(({ bg, theme }) => ({
  backgroundColor: theme.colors[bg ?? "bg10"],
  ...(bg === "founder" && {
    backgroundColor: theme.colors.accent2LL,
  }),
  ...(bg === "medium" && {
    backgroundColor: theme.colors.accent1LL,
  }),
  ...(bg === "mission" && {
    backgroundColor: theme.colors.accent3LL,
  }),
  border: `2px solid ${theme.colors.fg}`,
  borderRadius: theme.borderRadius.round,
  color: theme.colors.fg,
  display: "inline-block",
  fontSize: theme.fontSizes.xxs,
  padding: ".15em 1em 0",
  "&:hover, &:focus-visible": {
    color: theme.colors.fg,
  },
  "a:has(&)": {
    display: "inline-flex",
    alignItems: "center",
    textDecoration: "none",
  },
  "a:hover &, a:focus-visible &": {
    color: theme.colors.bg,
    backgroundColor: theme.colors.fg,
    textDecoration: "none",
  },
}));

export { Tag };
export type { TagProps };
