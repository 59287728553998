import styled from "@emotion/styled";
import {
  IconBag,
  IconCrown,
  IconDiamond,
  IconEthereum,
  IconGlobe,
  IconGlobeAlt,
  IconGraduationCap,
  IconHearts,
  IconKeys,
  IconMapMarker,
  IconRaisedHands,
  IconUsers,
  Text,
  WordBreak,
} from "atoms";
import texture from "assets/images/hug-brush-4.svg";

const categoryImageMap = {
  // project: generativemasks
  "Merchandise/Physical Goods": <IconBag accent="accent3" />,
  // project: generativemasks
  "Education/Resources": <IconGraduationCap accent="accent3" />,
  // project: generativemasks
  "Allowlists/Partnerships": <IconRaisedHands accent="accent4" />,
  // project: generativemasks
  "Royalty Distribution": <IconCrown accent="accent3" />,
  // project: generativemasks
  "Social Impact": <IconHearts accent="accent4" />,
  // project: women-hackers-club
  "Tokenomics/Staking": <IconEthereum accent="accent1" />,
  // project: nouns
  DAO: <IconUsers accent="accent4" />,
  // project: deadfellaz
  "IRL Experiences": <IconMapMarker accent="accent4" />,
  // project: deadfellaz
  Metaverse: <IconGlobeAlt accent="accent1" />,
  // Project: ruler-of-the-land
  "Game/P2E": <IconDiamond accent="accent1" />,
  // Project: ???
  "Platform and Tools": <IconKeys accent="accent3" />,
  "For The Culture": <IconGlobe accent="accent1" />,
} as const;

type Category = keyof typeof categoryImageMap;

interface Props {
  categories: string[];
}

function RoadmapCategories({ categories }: Props) {
  return (
    <RoadmapContainer>
      {categories.map((category) => (
        <RoadmapCategory key={category}>
          <RoadmapIcon>{categoryImageMap[category as Category]}</RoadmapIcon>
          <Text size="xs" bold>
            <WordBreak value={category} on="/" />
          </Text>
        </RoadmapCategory>
      ))}
    </RoadmapContainer>
  );
}

const width = "180px";

const RoadmapContainer = styled.ul({
  display: "grid",
  gap: "1rem",
  gridTemplateColumns: "repeat(2, calc(50% - .5rem))",
  justifyContent: "start",
  listStyleType: "none",
  margin: 0,
  padding: 0,
  "@media (min-width: 360px)": {
    gridTemplateColumns: `repeat(auto-fill, minmax(${width}, 1fr))`,
  },
});

const RoadmapCategory = styled.li(({ theme }) => ({
  alignItems: "center",
  background: theme.colors.bg,
  borderRadius: theme.borderRadius.sm,
  boxShadow: theme.boxShadow.light,
  color: theme.colors.fg,
  display: "grid",
  gap: 0,
  gridTemplateRows: "180px auto",
  gridTemplateColumns: "1fr",
  justifyContent: "center",
  maxWidth: "100%",
  padding: 0,
  textAlign: "center",
  position: "relative",
  p: {
    ...theme.fonts.display,
    display: "inline-block",
    lineHeight: "1.2",
    margin: 0,
    padding: ".5rem",
    fontSize: 14,
    textTransform: "uppercase",
    color: theme.colors.headingColor,
  },
  "&::before": {
    content: "''",
    position: "absolute",
    height: 180,
    top: 0,
    left: 0,
    right: 0,
    backgroundImage: `url('${texture}')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "200%",
    backgroundPosition: "50% 50%",
    opacity: 0.035,
    zIndex: 0,
  },
}));

const RoadmapIcon = styled.div(({ theme }) => ({
  aspectratio: "1",
  backgroundColor: theme.colors.bg,
  border: `1px solid ${theme.colors.fg10}`,
  borderRadius: theme.borderRadius.round,
  display: "grid",
  padding: "1.75em",
  placeItems: "center",
  placeSelf: "center",
  position: "relative",
  zIndex: 1,
  svg: {
    display: "block",
    fontSize: "4em",
  },
}));

export { RoadmapCategories };
