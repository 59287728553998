import * as React from "react";
import styled from "@emotion/styled";
import notebookBackground from "assets/images/notebook-preview-bg.jpg";

type NotebookPreviewProps = {
  children: React.ReactNode;
};
/**
 * Wrap an image with this component to create a
 * mockup of a spiral notebook with the image as it's cover
 */
function NotebookPreview({ children, ...props }: NotebookPreviewProps) {
  return (
    <Background {...props}>
      <Cover>{children}</Cover>
    </Background>
  );
}
const Background = styled.div({
  display: "block",
  position: "relative",
  backgroundImage: `url("${notebookBackground}")`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  aspectRatio: 0.75,
  width: "100%",
  maxWidth: "100%",
});

const Cover = styled.div({
  borderRadius: 2,
  position: "absolute",
  inset: "1.7% 5.3% 5.1% 5.9%",
  overflow: "hidden",
  mixBlendMode: "darken",
  "&>*, img": {
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },
});

export { NotebookPreview };
