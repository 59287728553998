import { useMutation } from "react-query";
import { api } from "utils/api";

type CreateProductReq = {
  artistId: string;
  assetUrl: string;
  productName: string;
  templateId: string;
};

type CreateProductResp = {
  shopifyProductId: string;
};

const postCreateProduct = (formData: CreateProductReq) =>
  api.post<CreateProductResp>("/shop/products", {
    body: JSON.stringify(formData),
  });

/**
 * Create a shopify Product
 */
const useCreateProduct = () => useMutation(postCreateProduct);

export type { CreateProductReq };
export { useCreateProduct };
