import {
  type IconProps,
  IconReactionMehNoBg,
  IconReactionNahNoBg,
  IconReactionYayNoBg,
  IconReactionMustHug2,
  IconReactionChefsKiss,
  IconReactionMindBlowing,
  IconReactionNeedItNow,
  IconReactionObsessed,
  IconReactionOutOfThisWorld,
  IconReactionTakeMyMoney,
  IconReactionThoughtProvoking,
} from "./icons";

const projectReactionsMap = {
  "Must Hug": IconReactionMustHug2,
  Yay: IconReactionYayNoBg,
  Meh: IconReactionMehNoBg,
  Nah: IconReactionNahNoBg,
} as const;
type ProjectReaction = keyof typeof projectReactionsMap;

const artistReactionsMap = {
  "Must Hug": IconReactionMustHug2,
  "Chef's kiss": IconReactionChefsKiss,
  "Mind-blowing": IconReactionMindBlowing,
  "Need it now": IconReactionNeedItNow,
  Obsessed: IconReactionObsessed,
  "Out of this world": IconReactionOutOfThisWorld,
  "Take my money": IconReactionTakeMyMoney,
  "Thought-provoking": IconReactionThoughtProvoking,
} as const;
type ArtistReaction = keyof typeof artistReactionsMap;

const reactionsMap = { ...projectReactionsMap, ...artistReactionsMap } as const;
type Reaction = keyof typeof reactionsMap;

type RatingIconProps = IconProps & {
  rating: Reaction;
};

function RatingIcon({ rating, ...props }: RatingIconProps) {
  const MappedIcon = reactionsMap[rating];
  return MappedIcon ? <MappedIcon {...props} /> : null;
}

export type { ArtistReaction, ProjectReaction };
export { RatingIcon };
