import styled from "@emotion/styled";
import {
  IconReactionMustHugWhiteBg,
  IconReactionYayWhiteBg,
  IconReactionMehWhiteBg,
  IconReactionNahWhiteBg,
} from "atoms";

type RatingProps = {
  score?: number;
  reviewCount?: number;
};

function HugScore({ score, reviewCount = 0 }: RatingProps) {
  let Wrapper = NotYetRated;
  let Icon = IconReactionMustHugWhiteBg;
  let text = "--";

  if (typeof score === "number" && reviewCount > 2) {
    Wrapper = Rating;
    if (score < 0.5) {
      Icon = IconReactionNahWhiteBg;
      text = "Nah";
    } else if (score < 1.5) {
      Icon = IconReactionMehWhiteBg;
      text = "Meh";
    } else if (score < 2.5) {
      Icon = IconReactionYayWhiteBg;
      text = "Yay";
    } else {
      text = "Must Hug";
    }
  }

  return (
    <Wrapper>
      <Icon aria-hidden="true" />
      <span>{text}</span>
    </Wrapper>
  );
}

const Rating = styled.div(({ theme }) => ({
  alignItems: "center",
  background: "none",
  border: 0,
  borderRadius: theme.borderRadius.xs,
  display: "flex",
  fontSize: theme.fontSizes.sm,
  gap: "0.25rem",
  svg: {
    height: "3em",
    margin: "-0.5em",
    position: "relative",
    width: "3em",
    zIndex: 1,
    circle: {
      display: "none",
    },
  },
  ".ProjectCard &": {
    fontSize: 12,
    svg: {
      height: "1.7em",
      width: "1.7em",
    },
    span: {
      opacity: ".75",
      whiteSpace: "nowrap",
    },
  },
}));
const NotYetRated = styled(Rating)({
  filter: "grayscale(1)",
  opacity: ".6",
});

export { HugScore };
