import * as React from "react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { isDev, isTest, featureFlags } from "config";
import { ApiError } from "utils/api";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // React-Query retries failed network requests with exponentially growing delays. This
      // customizes that behavior to prevent endless retries and maxes at 3. It also prevents
      // retries while testing and for client error responses (like a 401 that will continue
      // to fail until the user signs in, or a 404 for a non-existent resource).
      retry: (failures, error) => {
        const failureMax = isTest ? 0 : 3;
        if (failures >= failureMax) {
          return false;
        }
        if (error instanceof ApiError && error.status < 500) {
          return false;
        }
        return true;
      },
      // Do not refetch when window regains focus in development environment. This helps simplify
      // debugging when clicking back and forth between window and dev tools.
      refetchOnWindowFocus: !isDev,
    },
  },
});

interface QueryProviderProps {
  children: React.ReactNode;
}

function QueryProvider({ children }: QueryProviderProps) {
  return (
    <QueryClientProvider client={queryClient}>
      {children}

      {featureFlags.enableQueryDevTools && <ReactQueryDevtools />}
    </QueryClientProvider>
  );
}

const useTestHook = () => useQuery("testHook", () => "success!");

export { QueryProvider, useTestHook };
