import * as React from "react";
import styled from "@emotion/styled";
import { Money } from "@shopify/hydrogen-react";
import type { ProductPriceRange } from "@shopify/hydrogen-react/storefront-api-types";
import {
  AbsoluteLink,
  AllCaps,
  BlockTag,
  Button,
  FadeIn,
  FlexRow,
  ScreenReaderText,
  Stack,
  Text,
} from "atoms";
import { preventForwarding } from "styles/styleUtils";
import { paths } from "utils/paths";
import { useToggle } from "utils/hooks";
import { uniqueId } from "lodash";
import type { CollectionQueryProductData } from "../api/useCollectionQuery";
import { isOnSale } from "../utils/isOnSale";
import { ProductImage } from "./ProductImage";
import { ProductTag } from "./ProductTag";
import { ProductCardEditActions } from "./ProductCardEditButtons";

type ProductParam = Pick<
  CollectionQueryProductData,
  | "artistId"
  | "availableForSale"
  | "compareAtPriceRange"
  | "featuredImage"
  | "priceRange"
  | "productType"
  | "status"
  | "templateId"
  | "title"
  | "id"
> & { handle?: string };

type ProductCardProps = {
  product?: ProductParam;
  artistName?: string;
  isEditing?: boolean;
  onEditSuccess?: (msg: string) => void;
};

function ProductCard({
  product,
  artistName,
  isEditing,
  onEditSuccess,
}: ProductCardProps) {
  // Generate Unique ID for each card message
  const [popupId] = React.useState(uniqueId());
  const [showCantEditMessage, toggleShowCantEditMessage] = useToggle(false);

  if (!product || !product.artistId) {
    return null;
  }

  const {
    artistId,
    availableForSale,
    compareAtPriceRange,
    featuredImage,
    handle,
    priceRange,
    productType,
    status,
    templateId,
    title,
    id,
  } = product;

  const productIsOnSale =
    !isEditing && isOnSale({ compareAtPriceRange, priceRange });
  const isBuilderProduct = !!templateId;
  const isEditingNonBuilderProduct = isEditing && !isBuilderProduct;

  return (
    <Card>
      {!isEditingNonBuilderProduct && (
        // Hide edit link if the product was not created with the builder
        <AbsoluteLink
          to={
            isEditing
              ? paths.artistShopProductEdit(artistId, id)
              : paths.artistShopProduct(artistId, handle ?? "")
          }
        >
          <ScreenReaderText>
            {title}
            {!availableForSale && <span>, Sold out</span>}
          </ScreenReaderText>
        </AbsoluteLink>
      )}

      {isEditingNonBuilderProduct && (
        <AbsoluteButton
          isOpen={showCantEditMessage}
          onClick={() => toggleShowCantEditMessage()}
          aria-label="Toggle edit product message"
          aria-controls={`#${popupId}`}
          aria-expanded={showCantEditMessage}
        >
          {showCantEditMessage && (
            <ContactHugMessage id={popupId}>
              <Stack>
                <Text
                  font="display"
                  size="sm"
                  bold
                  style={{ textWrap: "balance" }}
                >
                  Contact <AllCaps>Hug</AllCaps> to edit this product
                </Text>

                <Text>
                  This product was created manually by <AllCaps>Hug</AllCaps>{" "}
                  staff and cannot be edited using the <AllCaps>Hug</AllCaps>{" "}
                  Product Builder.
                </Text>

                <Button size="xxxs" variant="secondary" aria-hidden as="div">
                  Close
                </Button>
              </Stack>
            </ContactHugMessage>
          )}
        </AbsoluteButton>
      )}

      <CardImageWrapper>
        {featuredImage && (
          <CardImage
            data={featuredImage}
            aspectRatio="1"
            sizes="(max-width: 420px) 100vw, (max-width: 740px) 50vw, (max-width: 1050px) 30vw,  (max-width: 1440px) 25vw, 300px"
            width="300"
            height="300"
          />
        )}
      </CardImageWrapper>

      <CardBody>
        <Stack gap="xxs">
          <ProductTag type={productType} size="xxs" />
          <CardTitle aria-hidden>{title}</CardTitle>
          {artistName && <ArtistName>{artistName}</ArtistName>}
        </Stack>

        <CardTagList
          status={status}
          isSale={productIsOnSale}
          isAvailable={availableForSale}
          isEditing={isEditing}
        />

        {isEditing && isBuilderProduct && (
          <ProductCardEditActions
            status={status}
            productId={id}
            artistId={artistId}
            onEditSuccess={onEditSuccess}
          />
        )}

        {!isEditing && (
          <FlexRow
            justifyContent="space-between"
            gap="0 2px"
            flexWrap="nowrap"
            itemsFlex="0 1 auto"
            alignItems="flex-end"
          >
            <Price>
              <ProductCardPrice
                priceRange={priceRange}
                compareAtPriceRange={compareAtPriceRange}
                productIsOnSale={productIsOnSale}
              />
            </Price>
          </FlexRow>
        )}
      </CardBody>
    </Card>
  );
}

const Card = styled.li(({ theme }) => ({
  borderRadius: theme.borderRadius.sm,
  boxShadow: theme.boxShadow.light,
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.colors.bg,
  overflow: "hidden",
  padding: 0,
  position: "relative",
  margin: 0,
  "&::marker": {
    visibility: "hidden",
    position: "absolute",
    opacity: 0,
  },
  "&:has(a:hover, a:focus) .ProductCardEditButton": {
    transform: "unset",
    color: theme.colors.fg,
    textDecoration: "none",
    borderBottomColor: theme.colors.fg,
    boxShadow: `0 0 0 2px ${theme.colors.bg}, 0 0 0 4px ${theme.colors.fg}`,
  },
}));

const CardImageWrapper = styled.div({
  width: "100%",
  aspectRatio: 1,
  backgroundImage: "linear-gradient(30deg, #dfdfdf 10%, #efefef 90%)",
});

const CardImage = styled(ProductImage)(({ theme }) => ({
  border: 0,
  borderRadius: `${theme.borderRadius.sm} ${theme.borderRadius.sm} 0 0`,
  display: "block",
  objectFit: "cover",
  width: "100%",
}));

const CardBody = styled.div({
  display: "flex",
  gap: 10,
  flexDirection: "column",
  justifyContent: "space-between",
  flex: 1,
  overflow: "hidden",
  paddingInline: "4%",
  paddingBlock: "4%",
});

const CardTitle = styled(Text)(({ theme }) => ({
  ...theme.fonts.display,
  color: theme.colors.fg,
  fontSize: theme.fontSizes.sm,
  lineHeight: 1,
  display: "-webkit-box",
  lineClamp: "2",
  margin: 0,
  overflow: "hidden",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
  textOverflow: "ellipsis",
  wordBreak: "break-word",
}));

const ArtistName = styled(Text)({
  fontSize: 12,
  margin: 0,
  lineHeight: 1,
});

const Price = styled.div({
  fontSize: 16,
});

const CardTags = styled(FlexRow)({
  position: "absolute",
  zIndex: 11,
  gap: 5,
  top: 10,
  left: 10,
});

const AbsoluteButton = styled.button<{ isOpen: boolean }>(
  ({ isOpen, theme }) => ({
    appearance: "none",
    position: "absolute",
    inset: 0,
    backgroundColor: isOpen ? "rgb(0 0 0 / .25)" : "rgb(0 0 0 / 0)",
    border: 0,
    cursor: "pointer",
    padding: 20,
    display: "grid",
    placeItems: "center",
    borderRadius: theme.borderRadius.sm,
    transition: ".2s ease background-color",
    //
    "*:has( > & )": {
      transition: ".2s ease all",
    },
    "*:has( > & ):has(a:hover, a:focus-visible, button:hover, [aria-live]:hover)":
      {
        boxShadow: theme.boxShadow.dark,
        transform: "translateY(-2px)",
      },
  }),
);

const ContactHugMessage = styled(FadeIn)(({ theme }) => ({
  borderRadius: theme.borderRadius.sm,
  boxShadow: theme.boxShadow.light,
  padding: 20,
  backgroundColor: theme.colors.accent3LL,
  textAlign: "center",
}));

type ProductCardPriceProps = {
  priceRange: ProductPriceRange;
  compareAtPriceRange: ProductPriceRange;
  productIsOnSale: boolean;
};

function ProductCardPrice({
  priceRange,
  compareAtPriceRange,
  productIsOnSale,
}: ProductCardPriceProps) {
  const minPrice = priceRange.minVariantPrice?.amount;
  const maxPrice = priceRange.maxVariantPrice?.amount;
  const hasPriceRange = maxPrice !== minPrice;

  return (
    <ProductPrice>
      <FlexRow gap="0 3px">
        {hasPriceRange && <small>from</small>}
        <SaleMoney
          data={priceRange.minVariantPrice}
          productIsOnSale={productIsOnSale}
          withoutTrailingZeros
        />
      </FlexRow>

      {productIsOnSale && (
        <>
          <ScreenReaderText>marked down from</ScreenReaderText>
          <StrikeMoney
            data={compareAtPriceRange.minVariantPrice}
            withoutTrailingZeros
          />
        </>
      )}
    </ProductPrice>
  );
}

const ProductPrice = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  lineHeight: 1,
  flexWrap: "wrap",
  color: theme.colors.fg,
  gap: "0 4px",
}));

const StrikeMoney = styled(Money)(({ theme }) => ({
  fontSize: ".85em",
  color: theme.colors.fg60,
  textDecoration: "line-through",
}));

const SaleMoney = styled(Money, {
  shouldForwardProp: preventForwarding("productIsOnSale"),
})<{ productIsOnSale: boolean }>(({ productIsOnSale = false, theme }) => ({
  color: productIsOnSale ? theme.colors.error : theme.colors.fg,
  fontSize: 15,
}));

type CardTagListProps = {
  status?: string;
  isSale?: boolean;
  isAvailable?: boolean;
  isEditing?: boolean;
};

function CardTagList({
  status,
  isSale,
  isAvailable,
  isEditing,
}: CardTagListProps) {
  return (
    <CardTags>
      {status === "ACTIVE" && (
        <>
          {isSale && (
            <BlockTag bg="error" color="bg" size="xs">
              Sale
            </BlockTag>
          )}

          {!isAvailable && (
            <BlockTag bg="error" color="bg" size="xs">
              Sold out
            </BlockTag>
          )}
        </>
      )}

      {isEditing && status === "DRAFT" && (
        <BlockTag bg="bg" color="fg70" size="xs">
          Draft
        </BlockTag>
      )}

      {isEditing && status === "ARCHIVED" && (
        <BlockTag bg="fg80" color="bg" size="xs">
          Archived
        </BlockTag>
      )}
    </CardTags>
  );
}

export { ProductCard, SaleMoney, StrikeMoney };
