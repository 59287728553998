import styled from "@emotion/styled";
import { formatDate } from "utils/date";
import { Project } from "../types";

const collectionSizeText = (size: number | undefined): string => {
  if (size === -1) {
    return "Unlimited";
  }
  return !size ? "-" : new Intl.NumberFormat("en-US").format(size).toString();
};

function ProjectSpecs({ project }: { project: Project }) {
  return (
    <ProductSpecsList>
      <div>
        <dt>First Mint Date</dt>
        <dd>
          {formatDate(
            project.mintDate,
            {
              month: "short",
              day: "numeric",
              year: "numeric",
            },
            "TBD",
          )}
        </dd>
      </div>

      <div>
        <dt>Collection Size</dt>
        <dd>{collectionSizeText(project.collectionSize)}</dd>
      </div>

      <div>
        <dt>Doxxed</dt>
        <dd>{project.doxxed ?? "-"}</dd>
      </div>
    </ProductSpecsList>
  );
}

const ProductSpecsList = styled.dl(({ theme }) => ({
  color: theme.colors.bodyColor,
  display: "grid",
  gridArea: "project-specs",
  gap: ".5rem 1rem",
  marginBottom: "2rem",
  gridTemplateColumns: `repeat(auto-fill, minmax(110px, 1fr))`,
  dt: {
    ...theme.fonts.display,
    fontSize: theme.fontSizes.xs,
    fontweight: "bold",
    textTransform: "uppercase",
  },
  dd: {
    fontSize: theme.fontSizes.xs,
    paddingTop: 0,
    margin: 0,
    lineHeight: "1",
    svg: {
      height: "1em",
      width: "auto",
      marginRight: ".15em",
    },
  },
  "@media (min-width: 831px)": {
    display: "flex",
    flexWrap: "wrap",
    gap: "1em 2.5em",
  },
}));

export { ProjectSpecs };
