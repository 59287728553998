import {
  ParsedMetafields,
  flattenConnection,
  parseMetafield,
} from "@shopify/hydrogen-react";
import type {
  Product,
  MediaConnection,
  MetaobjectConnection,
  Metaobject,
  SelectedOption,
  MoneyV2,
} from "@shopify/hydrogen-react/storefront-api-types";
import { useInfiniteQuery, useQuery } from "react-query";
import { api } from "utils/api";
import { sanitizeShopifyHTML } from "../components/ShopifyRichText";
import { parseGid } from "../utils/shopifyId";
import { productAdminKeys } from "./queryKeys";
import type {
  MetaFieldImageData,
  MetaFieldShippingInfo,
  ProductAdminData,
  ProductStatus,
  VariantAdminNodeResp,
} from "../types";
import { useProductQuery, VariantNode } from "./useProductQuery";

type ProductAdminResp = Pick<
  Product,
  | "id"
  | "description"
  | "descriptionHtml"
  | "productType"
  | "tags"
  | "title"
  | "updatedAt"
  | "vendor"
  | "featuredImage"
  | "options"
> & {
  handle: string;
  artistId?: { value: string };
  publishedAt?: { value: string };
  status?: { value: ProductStatus };
  templateId?: { value: string };
  assetUrl?: { value: string };
  maximumQuantity?: { value: string };
  onChainJSON?: { value: string };
  imageData?: { value: string };
  details?: {
    value: string;
    references: Partial<MetaobjectConnection>;
  };
  variants: {
    nodes: VariantAdminNodeResp[];
  };
  compareAtPriceRange: {
    maxVariantCompareAtPrice: MoneyV2;
    minVariantCompareAtPrice: MoneyV2;
  };
  media: MediaConnection;
};

// the shape of data stored in the metafield
type OnChainJSON = {
  contractId: string;
  tokenName: string;
  tokenDescription: string;
  royalty: number;
};

/**
 * Get A Single Product
 */
const getProductAdmin = (id?: string | null) =>
  id
    ? api
        .get<{ product: ProductAdminResp }>(`/shop/product/${id}`)
        .then((data) => transformAdminProduct(data.product))
    : Promise.reject(new Error("Invalid product id"));

/**
 * Hook for a single product
 */
const useProductAdminQuery = (id?: string | null) =>
  useQuery(productAdminKeys.detail(id), () => getProductAdmin(id), {
    enabled: !!id,
  });

type AdminProductsRequest = {
  artistId?: string;
  status?: string;
  sort?: string[];
  templateId?: string;
  perPage?: number;
  searchAfter?: string;
};

/**
 * Get Array of Products by Status
 */
const getAdminProducts = async (params: AdminProductsRequest) => {
  const resp = await api.post<{
    data: ProductAdminResp[];
    searchAfter?: string;
  }>("/shop/search", { body: JSON.stringify(params) });

  return {
    data: resp.data.map((row) => transformAdminProduct(row)),
    searchAfter: resp.searchAfter,
  };
};

/**
 * Infinite Query Hook for Products
 */

const useAdminProducts = (params: AdminProductsRequest) =>
  useInfiniteQuery(
    productAdminKeys.list(params),
    ({ pageParam }) =>
      getAdminProducts({
        ...params,
        searchAfter: pageParam,
      }),
    {
      getNextPageParam: (lastPage) => lastPage.searchAfter,
    },
  );

/**
 * Convert API Data to our Internal Storage Format for Admin Data
 */
const transformAdminProduct = (product: ProductAdminResp): ProductAdminData => {
  // Flatten all connections from metafields or other relationships
  const details = !product.details?.references
    ? []
    : flattenConnection(product.details.references).filter(
        (d): d is Metaobject => !!d,
      );
  const media = !product.media ? [] : flattenConnection(product.media);

  const variants = !product.variants
    ? []
    : flattenConnection(product.variants).map((v) => ({
        ...v,
        // Parse variant metafield values
        templateVariantId: v.templateVariantId?.value,
        ...(JSON.parse(
          v.shippingDetails?.value ?? "{}",
        ) as MetaFieldShippingInfo),
      }));

  // Parse JSON Metafields
  const imageData =
    parseMetafield<ParsedMetafields<MetaFieldImageData>["json"]>(
      product.imageData ?? { type: "json", value: "{}" },
    ).parsedValue || undefined;

  const onChainJSON = parseMetafield<ParsedMetafields<OnChainJSON>["json"]>(
    product.onChainJSON ?? { type: "json", value: "{}" },
  ).parsedValue;

  const handle = product.handle ?? "";

  return {
    ...product,
    handle,
    id: parseGid(product.id).id,
    gid: product.id,
    artistId: product.artistId?.value,
    assetUrl: product.assetUrl?.value,
    descriptionHtml: sanitizeShopifyHTML(product.descriptionHtml),
    details,
    imageData,
    maximumQuantity: product.maximumQuantity?.value,
    media,
    ...onChainJSON,
    publishedAt: product.publishedAt?.value,
    status: product.status?.value,
    templateId: product.templateId?.value,
    variants,
    compareAtPriceRange: {
      maxVariantPrice: product.compareAtPriceRange?.maxVariantCompareAtPrice,
      minVariantPrice: product.compareAtPriceRange?.minVariantCompareAtPrice,
    },
  };
};

/**
 * Convert Admin Data Format to Storefront Data Format so we
 * can pass admin data to product cards
 */
function convertProductAdminToStoreFront(
  selectedOptions: SelectedOption[],
  product?: ProductAdminData,
): ReturnType<typeof useProductQuery>["data"] {
  if (!product) {
    return undefined;
  }

  let maxVariantPrice = 0;
  let minVariantPrice = 0;
  product.variants.forEach((v) => {
    const price = parseFloat(v.price.amount);
    if (minVariantPrice === 0 || price < minVariantPrice) {
      minVariantPrice = price;
    }
    if (maxVariantPrice === 0 || price > maxVariantPrice) {
      maxVariantPrice = price;
    }
  });

  const variants: VariantNode[] = product.variants.map((v) => ({
    ...v,
    price: { amount: v.price.toString(), currencyCode: "USD" },
    availableForSale: true,
  }));

  const selectedVariant = variants.find((v) => {
    const matches = selectedOptions.map((selectedOption) => {
      const variantOption = v.selectedOptions.find(
        (vo) => vo.name === selectedOption.name,
      );
      return variantOption?.value === selectedOption.value;
    });
    return matches.every((m) => m);
  });

  const handle = product.handle ?? "";

  return {
    ...product,
    handle,
    gid: product.id,
    hasVariants: product.variants.length > 0,
    priceRange: {
      minVariantPrice: {
        amount: minVariantPrice.toString(),
        currencyCode: "USD",
      },
      maxVariantPrice: {
        amount: maxVariantPrice.toString(),
        currencyCode: "USD",
      },
    },
    availableForSale: true,
    artistId: product.artistId ?? "",
    maximumQuantity: product.maximumQuantity ?? "",
    publishedAt: product.publishedAt ?? "",
    status: (product.status as ProductStatus) ?? "ACTIVE",
    templateId: product.templateId ?? "",
    variants,
    selectedVariant,
    // TODO - Finish Items Below
    upsells: [],
  };
}

export {
  useProductAdminQuery,
  useAdminProducts,
  transformAdminProduct,
  convertProductAdminToStoreFront,
  type ProductAdminResp,
};
