import * as React from "react";
import styled from "@emotion/styled";
import { Container } from "./Container";

function BetaBanner({
  children,
  ...props
}: React.ComponentProps<typeof Banner>) {
  return (
    <Banner {...props}>
      <Container>{children}</Container>
    </Banner>
  );
}

const Banner = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.accent3LL,
  paddingBlock: theme.spacing.gap,
}));

export { BetaBanner };
