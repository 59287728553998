import { H2, Link, Text, Stack } from "atoms";
import { useArtistApplication } from "../api/useArtistApplications";
import type { Artist } from "../types";
import { ArtistApplicationWorkflowStatus } from "./ArtistApplicationWorkflowStatus";

function ArtistAdminSection({ entity }: { entity: Artist }) {
  const rawApplicationId = entity.applicationId;
  const parsedApplicationId = rawApplicationId?.includes("~")
    ? rawApplicationId.split("~")?.[1]
    : rawApplicationId;
  const { data } = useArtistApplication(parsedApplicationId);
  const application = data?.application;

  return (
    <Stack
      gap="sm"
      style={{
        backgroundColor: "#f2f2f2",
        padding: "1em",
        borderRadius: 4,
        marginBottom: "1rem",
        marginTop: "2rem",
      }}
    >
      <Stack gap="0">
        <H2 size="md" style={{ margin: 0 }}>
          Profile Info
        </H2>
        <Text size="xxxs" as="small">
          <em>Visible to admins only</em>
        </Text>
      </Stack>

      <Text size="xxs">
        Status: {entity.status?.toLowerCase()}
        <br />
      </Text>

      <Stack gap="0">
        {entity.adminUsers?.length ? (
          <Stack gap="0">
            <Text size="xxs" bold>
              Users Claiming this Profile
            </Text>
            {entity.adminUsers.map((id) => (
              <Text size="xxs" key={id}>
                <Link to={`/admin/users/${id}`}>
                  {id.substring(0, 6)}...{id.slice(-6)}
                </Link>
              </Text>
            ))}
          </Stack>
        ) : (
          <Text size="xxs" bold>
            No claims to this profile
          </Text>
        )}
        <div>
          <Link to="admin" size="xs">
            <strong>Manage Claims to Profile</strong>
          </Link>
        </div>
      </Stack>

      {entity.applicationId && (
        <>
          <Text size="xxs">
            <Link to={`/admin/applications/${entity.applicationId}`}>
              View Artist Application
            </Link>
          </Text>
          {application && (
            <ArtistApplicationWorkflowStatus app={data?.application} />
          )}
        </>
      )}
    </Stack>
  );
}

export { ArtistAdminSection };
