import * as React from "react";
import styled from "@emotion/styled";
import {
  FieldPathByValue,
  FieldValues,
  useController,
  UseFormReturn,
} from "react-hook-form";
import {
  Button,
  FakeLabel,
  FieldError,
  FlexRow,
  Img,
  LoadingSpinner,
  RequiredText,
  Stack,
  Text,
} from "atoms";
import { formatFilename } from "utils/text";
import { useFileUpload } from "../api/useFileUpload";
import type { Image, UploadFileResp } from "../types";
import { FileInput } from "./FileInput";
import { ImageCropper } from "./ImageCropper";
import { PreviewVideo } from "./PreviewVideo";
import { generateThumbnail } from "../utils/generateThumbnail";
import { formatSize } from "../utils/formatSize";
import { getDimensionsFromUrl } from "../utils/getDimensionsFromUrl";
import { getTempPreviewUrl } from "../utils/getTempPreviewUrl";

const ImageAspectRatioMap = {
  square: 1.0,
  banner: 3.0,
};

type ImageAspectRatio = keyof typeof ImageAspectRatioMap;

type ImageUploadProps<
  TFieldValues extends FieldValues,
  TFieldName extends FieldPathByValue<TFieldValues, Image | undefined>,
> = {
  control: UseFormReturn<TFieldValues>["control"];
  errorMessage?: string;
  /**
   * Optional handler to use uploaded url or file imperatively
   */
  onSuccess?: (upload: UploadFileResp) => void;
  fieldName: TFieldName;
  src?: Image;
  entityType?: "ARTIST" | "PROJECT";
  maxFileSize?: number;
  accept?: React.ComponentProps<typeof FileInput>["accept"];
  /**
   * Optional aspect ratio to require image cropping, undefined value prevents need to crop
   */
  aspect?: ImageAspectRatio | number;
  autoFocus?: boolean;
  label?: React.ReactNode;
  required?: string | boolean;
  previewWidth?: React.CSSProperties["width"];
  fileToUpload?: File;
  hideReplaceButton?: boolean;
};

function ImageUpload<
  TFieldValues extends FieldValues,
  TFieldName extends FieldPathByValue<TFieldValues, Image | undefined>,
>({
  accept,
  aspect,
  autoFocus,
  control,
  entityType,
  errorMessage,
  fieldName,
  label,
  maxFileSize,
  onSuccess,
  previewWidth = "50%",
  required,
  src,
  fileToUpload,
  hideReplaceButton,
  ...props
}: ImageUploadProps<TFieldValues, TFieldName>) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const thumbnailRef = React.useRef<HTMLCanvasElement>(null);
  const timerRef = React.useRef<number>();

  const [file, setFile] = React.useState<File | undefined>(fileToUpload);

  const [preview, setPreview] = React.useState(src);
  const resetPreviewToSrc = () => setPreview(src);
  const removePreview = () => {
    setPreview(undefined);
    if (file) {
      setFile(undefined);
    }
  };

  const { mutate, data, isLoading, error } = useFileUpload();
  const thumbnailMutation = useFileUpload();

  const { field } = useController<TFieldValues, TFieldName>({
    control,
    name: fieldName,
    rules: {
      required: required ? "This upload is required" : undefined,
    },
  });

  // Clean up any remaining timeout on unmount
  React.useEffect(() => () => window.clearTimeout(timerRef.current), []);

  const requiresCrop = aspect !== undefined;
  const fileIsImage = !!file && file.type.startsWith("image");
  const previewIsVideo = !!preview && !!preview.videoUrl;

  const handleGenerateThumb = async () => {
    const thumbFile = await generateThumbnail(
      thumbnailRef.current,
      videoRef.current,
    );
    if (thumbFile) {
      thumbnailMutation.mutate(
        { file: thumbFile },
        {
          onSuccess: async (result) => {
            const thumbUrl = getTempPreviewUrl(result.url);
            const dimensions = await getDimensionsFromUrl(thumbUrl);
            field.onChange({
              ...field.value,
              url: result.url,
              ...dimensions,
            });
          },
        },
      );
    }
  };

  // Upload selected and/or cropped file and show upload preview
  const uploadFile = (newFile: File) => {
    mutate(
      { file: newFile },
      {
        // When upload is successful, update the parent component (e.g. set form field values)
        onSuccess: async (result) => {
          const isVideo = result.file.type.startsWith("video");
          const previewUrl = getTempPreviewUrl(result.url);
          const dimensions = isVideo
            ? undefined
            : await getDimensionsFromUrl(previewUrl);

          const img: Image = {
            url: isVideo ? "" : result.url,
            videoUrl: isVideo ? result.url : undefined,
            contentType: result.file.type,
            importDate: new Date().toISOString(),
            ...dimensions,
          };

          field.onChange(img);

          setPreview({
            ...img,
            url: isVideo ? "" : previewUrl,
            videoUrl: isVideo ? previewUrl : undefined,
          });

          if (onSuccess) {
            onSuccess(result);
          }
        },
      },
    );
  };

  // If given a file to upload from parent, run the upload immediately on mount
  React.useEffect(() => {
    if (fileToUpload) {
      uploadFile(fileToUpload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelCrop = () => {
    // Remove value from file input
    const element = inputRef.current;
    if (element) {
      element.value = "";
    }

    // Reset to preview if original src exists, otherwise show empty file input
    if (src) {
      resetPreviewToSrc();
    } else {
      removePreview();
    }
  };

  let previewVariant: React.ComponentProps<typeof Img>["variant"] = "default";
  if (entityType === "ARTIST") {
    previewVariant = "circle";
  }
  if (entityType === "PROJECT") {
    previewVariant = "rounded";
  }

  // Once video has enough data to start playing, there should be a first frame showing. Make
  // doubly sure by adding a timeout. Then generate the first thumbnail of the preview video.
  const onCanPlay = () => {
    if (!src?.url && thumbnailMutation.isIdle) {
      // Save timeout in case we need to clean it up on unmount
      timerRef.current = window.setTimeout(handleGenerateThumb, 500);
    }
  };

  const previewAspectRatio =
    preview && preview.width && preview.height
      ? `${preview.width}/${preview.height}`
      : "3/4";

  return (
    <div {...props}>
      {label && <FakeLabel>{label}</FakeLabel>}

      {!hideReplaceButton && preview && (
        <Text size="xxs" bold style={{ margin: 0 }}>
          <Button onClick={removePreview} size="xxs" variant="blank">
            Replace <span>{previewIsVideo ? "Video" : "Image"}</span>
          </Button>
        </Text>
      )}

      {preview ? (
        <>
          <UploadedPreview>
            <Stack gap="0">
              <div>
                {previewIsVideo ? (
                  <PreviewVideo
                    videoRef={videoRef}
                    src={preview.videoUrl ?? ""}
                    onCanPlay={onCanPlay}
                    crossOrigin="anonymous"
                  />
                ) : (
                  <Img
                    src={preview.url}
                    alt="Upload preview"
                    variant={previewVariant}
                    style={{ width: previewWidth }}
                  />
                )}
              </div>
              <FlexRow gap="10px">
                {data && (
                  <Text size="xxs" style={{ margin: 0 }}>
                    {formatFilename(data.file.name)} —{" "}
                    {formatSize(data.file.size)}
                  </Text>
                )}
              </FlexRow>
            </Stack>
          </UploadedPreview>

          {previewIsVideo && (
            <ThumbnailUploadContainer>
              <FakeLabel>
                Thumbnail Image <RequiredText />
              </FakeLabel>

              <FlexRow itemsFlex="1 1 150px" alignItems="flex-start">
                <ThumbnailPreviewWrapper
                  style={{ aspectRatio: previewAspectRatio }}
                >
                  {preview.url && (
                    <img
                      src={preview.url}
                      alt="Thumbnail preview of your video"
                    />
                  )}
                  <canvas
                    ref={thumbnailRef}
                    width={preview.width}
                    height={preview.height}
                  />
                </ThumbnailPreviewWrapper>

                <Stack>
                  <Button
                    variant="secondary"
                    size="xxs"
                    onClick={() => handleGenerateThumb()}
                  >
                    Generate Thumbnail
                  </Button>

                  <Text size="xxxs">
                    A thumbnail image has been created using the first frame of
                    your video. Click &ldquo;Generate Thumbnail&rdquo; when the
                    above video is on a new frame to use it as the thumbnail
                    image.
                  </Text>
                </Stack>
              </FlexRow>
            </ThumbnailUploadContainer>
          )}
        </>
      ) : (
        <>
          {!file && (
            <FileInput
              autoFocus={autoFocus}
              accept={accept}
              maxFileSize={maxFileSize}
              onCancel={src ? resetPreviewToSrc : undefined}
              onChange={requiresCrop ? setFile : uploadFile}
              ref={inputRef}
            />
          )}

          {fileIsImage && requiresCrop && (
            <ImageCropper
              aspect={
                typeof aspect === "string"
                  ? ImageAspectRatioMap[aspect]
                  : aspect
              }
              file={file}
              onCancel={cancelCrop}
              onSubmit={uploadFile}
            />
          )}
        </>
      )}

      {(isLoading || thumbnailMutation.isLoading) && (
        <LoadingSpinner text="Uploading" />
      )}

      {!!(errorMessage ?? error) && (
        <FieldError>
          {errorMessage ?? "Sorry, the upload failed. Try again."}
        </FieldError>
      )}
    </div>
  );
}

const UploadedPreview = styled.div({
  display: "block",
});

const ThumbnailUploadContainer = styled.div({
  margin: "1rem 0 0",
});

const ThumbnailPreviewWrapper = styled.div(({ theme }) => ({
  position: "relative",
  width: "50%",
  "&>*": {
    position: "absolute",
    display: "block",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    border: "1px solid",
    borderColor: theme.colors.fg30,
    borderRadius: 4,
  },
}));

export { ImageUpload };
