import { useQuery } from "react-query";
import { api } from "utils/api";
import { Artist } from "../types";
import { artistKeys } from "./queryKeys";

const getArtistById = (artistId?: string) =>
  artistId
    ? api.get<Artist>(`/artists/${artistId}`)
    : Promise.reject(new Error("Invalid artist id"));

const useArtist = (artistId?: string) =>
  useQuery(artistKeys.detail(artistId), () => getArtistById(artistId), {
    enabled: !!artistId,
  });

export { useArtist, getArtistById };
