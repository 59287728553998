import * as React from "react";
import styled from "@emotion/styled";
import { preventForwarding } from "styles/styleUtils";
import { ImgBackground } from "./GenericImage";
import type { Image } from "../types";
import { useImgState } from "../utils/useImgState";

/** Recommended width in pixels for uploaded banner image */
const RECOMMENDED_BANNER_WIDTH = 2000;

type ReviewableBannerProps = Omit<
  React.ComponentProps<typeof BannerOrFallbackImg>,
  "src" | "isFallbackSrc"
> & {
  src?: Image;
  fallback: string | Image;
};

const createImgAttributes = (src: string | Image) => {
  if (typeof src === "string") {
    return { src };
  }

  let srcSet: string | undefined;
  if (src.url600 || src.url1200) {
    srcSet = [
      src.url600 ? `${src.url600} 600w` : null,
      src.url1200 ? `${src.url1200} 1200w` : null,
      src.url ? `${src.url} ${RECOMMENDED_BANNER_WIDTH}w` : null,
    ]
      .filter(Boolean)
      .join(", ");
  }

  return {
    src: src.url,
    srcSet,
    sizes: srcSet ? "100vw" : undefined,
  };
};

/**
 * Banner image component for ReviewableTemplate
 */
function ReviewableBanner({
  src,
  fallback,
  className,
  ...props
}: ReviewableBannerProps) {
  // Use the blur if src isn't available, or if the src is NSFW
  const isFallbackSrc = !src || !!src?.tags?.includes("NSFW");

  const { blur, onLoad } = useImgState({
    src: isFallbackSrc ? fallback : src,
    ...props,
  });

  // Build <img> attributes depending on `src` type
  const attrs = createImgAttributes(isFallbackSrc ? fallback : src);

  return (
    <BannerOrFallbackImgBackground
      isFallbackSrc={isFallbackSrc}
      className={className}
      style={{ "--bg-img": blur }}
    >
      <BannerOrFallbackImg
        {...attrs}
        {...props}
        isFallbackSrc={isFallbackSrc}
        onLoad={onLoad}
      />
    </BannerOrFallbackImgBackground>
  );
}

type WithIsFallbackSrc = {
  isFallbackSrc: boolean;
};

const BannerOrFallbackImg = styled.img<WithIsFallbackSrc>(
  ({ isFallbackSrc }) => ({
    aspectRatio: "auto !important",
    display: "block",
    height: "100%",
    objectFit: "cover",
    objectPosition: "center",
    position: "relative",
    transformOrigin: "center",
    width: "100%",
    ...(isFallbackSrc && {
      filter: "blur(5vw) brightness(1.1)",
      transform: "scale(-2, 2) translate(10%, 0)",
    }),
  }),
);

const BannerOrFallbackImgBackground = styled(ImgBackground, {
  shouldForwardProp: preventForwarding<WithIsFallbackSrc>("isFallbackSrc"),
})<WithIsFallbackSrc>(({ isFallbackSrc }) => ({
  maxHeight: 320,

  ...(isFallbackSrc
    ? {
        "&:before": {
          filter: "blur(5vw) brightness(1.1)",
          transform: "scale(-2, 2) translate(10%, 0)",
          transformOrigin: "center",
        },
      }
    : {}),
}));

export { ReviewableBanner, RECOMMENDED_BANNER_WIDTH };
